<latch-loading [isActive]="isLoading">
  <latch-card class="page fixed">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="handleClose()">
          <span class="latch-icon-close"></span>
        </button>
      </div>
      <div class="latch-card-title">Add Delivery Log</div>
    </ng-container>
    <ng-container latchCardContent>
      <latch-list [formGroup]="activityForm">
        <latch-labeled-list-item label="Delivery type">
          <div>
            <latch-dropdown [data]="deliverySelect" formControlName="deliveryType"></latch-dropdown>
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <div class="list-items-title">Building</div>
        <latch-labeled-list-item class="half" label="Name">
          <input class="form-control" formControlName="buildingName" type="text">
        </latch-labeled-list-item>
        <latch-labeled-list-item class="half" label="Organization">
          <input class="form-control" formControlName="organization" type="text">
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item label="Door">
          <div>
            <latch-dropdown *ngIf="locksSelect" [data]="locksSelect" formControlName="lock"></latch-dropdown>
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <div class="list-items-title">Delivery</div>
        <latch-labeled-list-item label="Courier">
          <latch-autocomplete placeholder="UPS" [items]="couriers" [autofocus]="false"
            (valueChanged)="setCourierValue($event)"></latch-autocomplete>
          <div class="has-error help-block" *ngIf="activityForm.get('courier')?.errors?.required">
            Courier field cannot be empty
          </div>
          <div class="has-error help-block"
            *ngIf="activityForm.get('courier')?.errors?.minlength || activityForm.get('courier')?.errors?.maxlength">
            Courier length must be between {{courierMinLength}} and {{courierMaxLength}}
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item label="Comment">
          <input class="form-control" formControlName="comment" placeholder="Comment">
          <div class="has-error help-block"
            *ngIf="activityForm.get('comment')?.errors?.minlength || activityForm.get('comment')?.errors?.maxlength">
            Comment length must be between {{commentMinLength}} and {{commentMaxLength}}
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item class="half" label="Status">
          <div>
            <latch-dropdown [data]="statusSelect" formControlName="status"></latch-dropdown>
          </div>
        </latch-labeled-list-item>
        <latch-labeled-list-item class="half" label="Priority">
          <div>
            <latch-dropdown [data]="prioritySelect" formControlName="priority"></latch-dropdown>
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item label="Created time">
          <latch-date-time-input formControlName="deliveryDateTime"></latch-date-time-input>
          <div class="has-error help-block" *ngIf="activityForm.get('deliveryDateTime')?.errors?.invalidDateTime">
            Invalid Date
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item>
          <latch-activity-units [activityUnits]="activityUnits" [numberOfUnitsPerAdd]="3" [isEditable]="true">
          </latch-activity-units>
          <div class="has-error help-block" *ngIf="activityForm.get('units')?.errors?.invalidUnits">
            Apartment unit and number of parcels is required to save the log.
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item label="Operator">
          <input class="form-control" formControlName="operator" type="text">
        </latch-labeled-list-item>
      </latch-list>
    </ng-container>
    <ng-container latchCardFooter>
      <div class="modal-buttons" *ngIf="enableDoorUnlock">
        <div class="button-element">
          <button class="latch-button-link" [disabled]="!activityForm.valid" (click)="submit(false)">
            Add log only
          </button>
        </div>
        <div class="button-element">
          <button class="latch-button latch-blue" [disabled]="!activityForm.valid" (click)="submit(true)">
            Add & Unlock
          </button>
        </div>
        <div class="button-element"></div>
      </div>
      <div class="modal-buttons" *ngIf="!enableDoorUnlock">
        <div class="button-element"></div>
        <div class="button-element">
          <button class="latch-button latch-blue" [disabled]="!activityForm.valid" (click)="submit(false)">
            Save Log
          </button>
        </div>
        <div class="button-element"></div>
      </div>
    </ng-container>
  </latch-card>
</latch-loading>
