import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[latchOutsideClick]'
})
export class OutsideClickDirective {
  @Input() public skipFirstOutsideClick = true;
  @Output() public latchOutsideClick: EventEmitter<null> = new EventEmitter();
  private mouseOver = false;

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    this.mouseOver = true;
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    this.mouseOver = false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (this.skipFirstOutsideClick) {
      this.skipFirstOutsideClick = false;
    } else if (!this.mouseOver) {
      this.latchOutsideClick.emit();
    }
  }
}
