import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { propertyManagers } from '../../../mock-db/propery-managers';
import { PropertyManager } from '../../../models/property-manager';
import { mockResponse } from '../../../utilities/mock-response';
import { ManagerService } from './manager.service';

@Injectable({
  providedIn: 'root'
})
export class MockManagerService extends ManagerService {

  constructor() {
    super();
  }

  getPropertyManagers(buildingUUID: string): Observable<PropertyManager[]> {
    return mockResponse(() => propertyManagers);
  }

  getPropertyManagerByPersonUUID(buildingUUID: string, personUUID: string): Observable<PropertyManager> {
    return mockResponse(() => {
      const propertyManager = propertyManagers.find(manager => manager.personUUID === personUUID);
      if (!propertyManager) {
        throw new Error('Property manager not found');
      }
      return propertyManager;
    });
  }
}
