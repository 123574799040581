import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BackendErrorMessageMap, ErrorStatus, ErrorUserMessage } from '../../models/errors';
import { NotificationService } from '../notification/notification.service';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

export class AppError extends Error {

  public static UserHasNoAccount = 'No accounts returned by accounts endpoint';
  public static UserHasNoBuildings = 'No buildings returned by buildings endpoint';

  public status: ErrorStatus;

  constructor(status: ErrorStatus, message: string) {
    super(message);
    // https://stackoverflow.com/a/41429145/712895
    Object.setPrototypeOf(this, AppError.prototype);
    this.status = status;
  }

}

const BackendErrorStatusMap: Record<string, ErrorStatus> = {
  400: ErrorStatus.Application,
  401: ErrorStatus.Permission,
  403: ErrorStatus.Permission,
  404: ErrorStatus.Application,
  500: ErrorStatus.System
};

const BackendErrorMessages = {
  SessionExpired: 'session expired - please re-login'
};

const ClientErrorMessageMap = {
  [AppError.UserHasNoAccount]: 'You do not have permission to manage any portfolios.',
  [AppError.UserHasNoBuildings]: 'You do not have permission to manage any portfolios or properties.'
};

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {

  public currentError: ErrorUserMessage | undefined;

  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) { }

  public handleException(error: any): void {
    if (environment.name === 'mock' || environment.name === 'dev-no-logs' || environment.name === 'local') {
      // eslint-disable-next-line no-restricted-syntax, no-console
      console.info('Unhandled exception', error);
    }

    if (!navigator.onLine) {
      this.currentError = {
        header: 'Network error',
        message: 'Unable to connect to the internet. Please check your connection.'
      };
    } else if (error instanceof HttpErrorResponse) {

      const backendMessage = AuthService.getPayload(error) as keyof typeof BackendErrorMessageMap;

      const status = error.status.toString();
      this.currentError = {
        header: BackendErrorStatusMap[status],
        message: BackendErrorMessageMap[backendMessage]
      };

      if (backendMessage === BackendErrorMessages.SessionExpired) {
        this.notificationService.clearError();
        this.router.navigate(['/login']);
        return;
      }

    } else if (error instanceof AppError) {

      this.currentError = {
        header: error.status,
        message: ClientErrorMessageMap[error.message]
      };

      // It should not be possible to reach either the UserHasNoAccount or UserHasNoBuildings cases - if the
      // user does not have any account or building, the backend should refuse to give them a MANAGER_WEB token.
      // However, when possible we shouldn't assume that the backend has successfully maintained all their
      // invariants, so we do our best to handle here by navigating the user to a special error page.

      if (error.message === AppError.UserHasNoAccount) {
        this.router.navigate(['/403'], {
          replaceUrl: true
        });
        return;
      }

      if (error.message === AppError.UserHasNoBuildings) {
        this.router.navigate(['/403'], {
          replaceUrl: true
        });
        return;
      }

    } else if (error.message in BackendErrorMessageMap) {
      const errorMessage = error.message as keyof typeof BackendErrorMessageMap;
      // Some services (HTTPKeyService and HTTPMfaService) strip their HttpErrorResponse errors out of the HttpErrorResponse object
      // before handing them off to us, so would not be caught by the instanceOf HttpErrorResponse which was meant to handle them above.
      this.currentError = {
        header: error.message === 'USER_FORBIDDEN' ? 'Restricted Permissions' : 'Error',
        message: BackendErrorMessageMap[errorMessage]
      };
    }

    let header; let message;
    if (this.currentError) {
      header = this.currentError.header;
      message = this.currentError.message;
    }
    this.notificationService.showError(
      header || 'Unexpected error',
      message || 'An unexpected error occurred.'
    );
  }

  public clear(): void {
    this.currentError = undefined;
    this.notificationService.clearError();
  }

}
