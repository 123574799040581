import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { LatchDialogRef, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { MessageDialogData } from '../../models/message-dialog';

@Component({
  selector: 'latch-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit, OnDestroy {
  public messageDialogType?: string;
  public title?: string;
  public message?: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(LATCH_DIALOG_DATA) @Optional() private dialogData: MessageDialogData | null,
    @Inject(LatchDialogRef) @Optional() private dialogRef: LatchDialogRef<void> | null,
  ) {
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.setDialogData(this.dialogData);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setDialogData(dialogData: MessageDialogData): void {
    this.messageDialogType = dialogData.type;
    this.title = dialogData.title;
    if (dialogData.message) {
      this.message = dialogData.message;
    }
    if (dialogData.autoClose) {
      this.setAutoCloseModal(dialogData.autoClose);
    }
  }

  private setAutoCloseModal(timer: number): void {
    of(null).pipe(
      takeUntil(this.unsubscribe$),
      delay(timer),
    ).subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }

}
