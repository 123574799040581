import { PropertyManager } from '../models/property-manager';
import { AMELIA_COTE, CORRINA_CALAHAN, DANIEL_CONROY } from './users';

export const ameliaCotePropertyManager: PropertyManager = {
  personUUID: 'amelia-cote-person-uuid',
  userUUID: AMELIA_COTE.uuid,
  firstName: AMELIA_COTE.firstName,
  lastName: AMELIA_COTE.lastName,
  email: AMELIA_COTE.email,
  phoneNumber: AMELIA_COTE.mobileNumber || null
};

export const corrinaCalahanPropertyManager: PropertyManager = {
  personUUID: 'corrina-calahan-person-uuid',
  userUUID: CORRINA_CALAHAN.uuid,
  firstName: CORRINA_CALAHAN.firstName,
  lastName: CORRINA_CALAHAN.lastName,
  email: CORRINA_CALAHAN.email,
  phoneNumber: CORRINA_CALAHAN.mobileNumber || null
};

export const danielConroyPropertyManager: PropertyManager = {
  personUUID: 'daniel-conroy-person-uuid',
  userUUID: DANIEL_CONROY.uuid,
  firstName: DANIEL_CONROY.firstName,
  lastName: DANIEL_CONROY.lastName,
  email: DANIEL_CONROY.email,
  phoneNumber: DANIEL_CONROY.mobileNumber || null
};

export const propertyManagers: PropertyManager[] = [
  ameliaCotePropertyManager,
  corrinaCalahanPropertyManager,
  danielConroyPropertyManager
];
