import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { Account } from '../../../models/account';

/**
 * Service which provides a list of accounts accessible to the current user.
 */
 @Injectable()
 export abstract class AccountService {

   protected accounts$: Observable<Account[]> | undefined;

   constructor(protected authService: AuthService) { }

   public initialize(): void {
     this.authService.session$.subscribe((session) => {
       if (!session) {
         this.accounts$ = undefined;
       }
     });
   }

   /**
    * this function forces a refresh of the accounts by emptying the cache of accounts
    */
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   public clearCache(): void { }

   /**
    * Returns a list of accounts.
    *
    * Uses the current user's (via AuthService) credentials.
    */
   abstract getAccounts(): Observable<Account[]>;

 }
