import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@latch/latch-web';
import { EMPTY, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { PasswordStep } from '../../models/account';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';

@Component({
  selector: 'latch-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
  public user: User | null = null;
  public otherActiveUserSessions: any;
  public isLoading = false;
  public passwordStep: PasswordStep = 'initiateChangePassword';

  private unsubscribe$ = new Subject<void>();
  constructor(
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.currentUser;
    this.isLoading = true;
    this.authService.getActiveUserSessions().pipe(
      tap(activeUserSessions => this.otherActiveUserSessions =
        activeUserSessions?.filter(item => item.currentSession === false)
      ),
      finalize(() => this.isLoading = false),
      catchError(() => EMPTY),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPasswordStep(step: PasswordStep): void {
    this.passwordStep = step;
  }

  handleChangePassword(userEmail: string): void {
    this.isLoading = true;
    this.authService.changePassword(userEmail).pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => this.isLoading = false)
    ).subscribe(() => this.setPasswordStep('confirmEmailSent'),
      error => this.errorHandlerService.handleException(error));
  }

}
