import { Account, AccountPermissions } from '../models/account';
import {
  building100Moffett,
  building150Claremont,
  building220w107,
  building85Attorney,
  building9InfiniteLoop,
  buildingRockefellerCenter,
  buildingRockefellerCenter2
} from './buildings';

export const account1: Account = {
  name: 'Account 1',
  uuid: 'account-my-account-1-uuid',
  permissions: [AccountPermissions.ManagementConsole, AccountPermissions.ViewDeliveryLogs],
  buildings: [
    building150Claremont,
    building220w107,
    building9InfiniteLoop,
    buildingRockefellerCenter,
  ]

};

export const account2: Account = {
  name: 'Account 2',
  uuid: 'account-my-account-2-uuid',
  permissions: [AccountPermissions.ManagementConsole, AccountPermissions.ViewDeliveryLogs],
  buildings: [
    building100Moffett,
    building85Attorney,
  ]
};

export const account3: Account = {
  name: 'Gotham',
  uuid: 'account-my-account-3-uuid',
  permissions: [AccountPermissions.ManagementConsole, AccountPermissions.ViewDeliveryLogs],
  buildings: [
    buildingRockefellerCenter2,
  ]
};

export const mockAccounts: Account[] = [
  account1,
  account2,
  account3
];
