import { Injectable } from '@angular/core';
import { ActiveUserSession, AuthClientType, AuthScope, LoginResponse, User } from '@latch/latch-web';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { currentLoginDate, MOCK_CURRENT_USER, previousLoginDate1, previousLoginDate2 } from '../../../mock-db/users';
import { mockResponse } from '../../../utilities/mock-response';
import { AuthService } from './auth.service';

@Injectable()
export class MockAuthService extends AuthService {
  redirectUrl = '';
  /**
   * Email address which the service should treat as valid and allow the user to log in with, in combination with
   * acceptPassword.
   */
  acceptEmail = 'demo@latch.com';
  /**
   * Password which the service should treat as valid and allow the user to log in with, in combination with acceptEmail.
   */
  acceptPassword = 'password';
  acceptAuthCode = '';
  hasPermission = true;

  private session = false;
  private xsrf: string | null = null;

  /** `isLoggedIn`  returns `true` to solve an issue when we run the app against the mock db
   * ISSUE: when running against the mock environment, without this fix, mock account service always returns `[]`
   * CAUSE: (~line:31 in mock-account.service.ts)
   *          if `!MockAuthService.isLoggedIn`, MockAccountService will always return empty list of accounts,
   *          but MockAuthService.getSession() always returns true,
   *          so the app behaves as if user has performed a successful login to a non-existent account
   * NOTE: this fix won't affect auth service or account service tests
   */
  public isLoggedIn = true;

  public currentUser = MOCK_CURRENT_USER;

  constructor() {
    super(null as any, null as any, null as any, null as any, null as any, null as any);
  }

  get user$(): Observable<User> {
    return of(this.currentUser);
  }

  getSession(session: boolean = this.session): Observable<boolean> {
    return mockResponse(() => true);
  }

  public login(email: string, password: string, mfaToken?: string, scope?: string): Observable<LoginResponse> {
    const validEmail = email === this.acceptEmail;
    const validPassword = password === this.acceptPassword;
    const validAuthCode = (mfaToken) ? (mfaToken === this.acceptAuthCode) : true;

    if (validEmail && validPassword && validAuthCode) {

      if (this.hasPermission) {
        this.session = true;
        this.initSession(MOCK_CURRENT_USER);

        // Todo: either clients should respond to complete notification (instead of next)
        // or this method should be able to return Observable<SomethingMeaningful>
        return mockResponse(() => ({ scope: 'MANAGER_WEB' } as LoginResponse));
      } else {
        this.session = false;

        return mockResponse(() => {
          const errorResponse = {
            ok: false,
            status: 401,
            statusText: 'Unauthorized'
          };
          throw errorResponse as any;
        });
      }

    } else {
      this.session = false;

      return mockResponse(() => {
        const errorResponse = {
          ok: false,
          status: 401,
          statusText: 'Unauthorized'
        };
        throw errorResponse as any;
      });
    }
  }

  logout(): Observable<null> {
    this.clearSession();
    return mockResponse(() => null);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public refreshUser(): void { }

  getActiveUserSessions(): Observable<ActiveUserSession[]> {
    const activeUserSessionSample = {
      clientType: AuthClientType.Web,
      scope: AuthScope.ManagerWeb,
      loginLocation: 'New York, USA',
      sessionIpAddress: '255.255.255.255'
    };

    const result: ActiveUserSession[] = [
      {
        ...activeUserSessionSample,
        createdAt: currentLoginDate,
        currentSession: true,
        uuid: 'active-user-session-1'
      },
      {
        ...activeUserSessionSample,
        createdAt: previousLoginDate1,
        currentSession: false,
        uuid: 'active-user-session-2'
      },
      {
        ...activeUserSessionSample,
        createdAt: previousLoginDate2,
        currentSession: false,
        uuid: 'active-user-session-3'
      }
    ];
    return of(result).pipe(
      delay(300)
    );
  }

  revokeSessions(): Observable<null> {
    return of(null).pipe(
      delay(300)
    );
  }

  protected initSession(user: User): void {
    this.xsrf = 'mock_xsrf';
  }

  protected clearSession(): void {
    this.xsrf = null;
  }
}
