import { Courier } from '../models/courier';

export const courierUPS: Courier = {
  name: 'UPS'
};

export const courierFedEx: Courier = {
  name: 'FedEx'
};

export const couriers: Courier[] = [
  courierUPS,
  courierFedEx
];
