import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Account, AccountPermissions } from '../../../models/account';
import { AuthService } from '../../services/auth/auth.service';
import { AccountService } from './account.service';
import { BuildingFeature } from 'src/app/models/building';

/**
 * Implementation of AccountService which queries real data from the Latch servers.
 */
 @Injectable()
 export class HttpAccountService extends AccountService {

  constructor(protected authService: AuthService) {
    super(authService);
  }

  getAccounts(): Observable<Account[]> {
    if (!this.accounts$) {
      this.accounts$ = this.authService
        .request({
          method: 'get',
          endpoint: '/web/v2/accounts'
        }).pipe(
          map((response: HttpResponse<any>): Account[] => {
            // Check that the response is in the format we expect; bail if not.
            const responseError = this.getResponseErrors(response);
            if (responseError) {
              // throwError allows the code to continue running, so ... Why would that exist? Who would want that?
              throw responseError;
            }

            // getResponseErrors checks that the data is in the correct format and has all required fields. We
            // happen to have data structures with fields that exactly match the endpoint's return format, so we can just
            // return the json object.
            return AuthService.getPayload(response).accounts;
          }),
          map(accounts =>
            accounts.filter(account =>
              account.uuid &&
              account.permissions?.some(p => p === AccountPermissions.ViewDeliveryLogs) &&
              (account.buildings?.filter(b => b.features?.some(f => f === BuildingFeature.DeliveryAssistant)).length ?? 0) > 0
            )
          ),
          shareReplay(1),
        );
    }

    return this.accounts$;
  }

  clearCache() {
    this.accounts$ = undefined;
  }

  private getResponseErrors(response: HttpResponse<any>): Error | null {
    if (!AuthService.hasValidPayload(response)) {
      const message = `Response has no payload: '${response.toString()}'`;
      return new Error(message);
    }
    const payload = AuthService.getPayload(response).accounts;

    if (!Array.isArray(payload)) {
      const message = `Received unexpected result from server: '${response.toString()}'`;
      return new Error(message);
    }

    for (const object of payload) {
      if (!('name' in object)) {
        let message = `Received unexpected result from server: '${response.toString()}'`;
        message += `; object '${object}' has no 'name' property.`;
        return new Error(message);
      }
      if (!('uuid' in object)) {
        let message = `Received unexpected result from server: '${response.toString()}'`;
        message += `; object '${object}' has no 'uuid' property.`;
        return new Error(message);
      }
    }

    return null;
  }

 }
