import { User } from '@latch/latch-web';

export const currentLoginDate = new Date();
currentLoginDate.setMinutes(0);
export const previousLoginDate1 = new Date();
previousLoginDate1.setHours(0);
export const previousLoginDate2 = new Date();
previousLoginDate2.setMonth(0);

export const MOCK_CURRENT_USER: User = {
  uuid: 'user-demo-user-uuid',
  firstName: 'Demo',
  lastName: 'User',
  email: 'demo@latch.com',
  mobileNumber: '+12342342345',
  anonymousUuid: '',
  signupDate: new Date(),
  currentLoginDate,
  previousLoginDates: [previousLoginDate1, previousLoginDate2]
};

export const AMELIA_COTE: User = {
  uuid: 'user-amelia-cote-uuid',
  firstName: 'Amelia',
  lastName: 'Cote',
  email: 'amelia.cote@latch.com',
  mobileNumber: '+12342342345',
  anonymousUuid: ''
};

export const CORRINA_CALAHAN: User = {
  uuid: 'user-corina-calahan-uuid',
  firstName: 'Corrina',
  lastName: 'Calahan',
  email: 'corina.calahan@latch.com',
  mobileNumber: '+12342342345',
  anonymousUuid: ''
};

export const DANIEL_CONROY: User = {
  uuid: 'user-daniel-conroy-uuid',
  firstName: 'Daniel',
  lastName: 'Conroy',
  email: 'daniel.conroy@latch.com',
  mobileNumber: '+12342342345',
  anonymousUuid: ''
};

export const mockUsers: User[] = [
  MOCK_CURRENT_USER,
  AMELIA_COTE
];
