import { Pipe, PipeTransform } from '@angular/core';
import { ActivityStatus } from '../../models/activity';

const statusNames: Record<ActivityStatus, string> = {
  [ActivityStatus.CLOSED]: 'Closed',
  [ActivityStatus.ON_HOLD]: 'On Hold',
  [ActivityStatus.OPEN]: 'Open',
  [ActivityStatus.PENDING]: 'Pending',
};

@Pipe({
  name: 'statusName'
})
export class StatusNamePipe implements PipeTransform {

  transform(status: ActivityStatus): string {
    return statusNames[status];
  }

}
