import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LatchVersionInfoService } from '@latch/latch-web';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AuthService } from '../../core/services/auth/auth.service';
import { ResetPasswordError } from '../../models/auth';

@Component({
  selector: 'latch-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('email') emailInput!: ElementRef;
  @ViewChild('captchaRef') reCaptcha!: RecaptchaComponent;

  form: UntypedFormGroup;

  statusMessage = '';

  hasSubmitted = false;

  versionNumber = '';

  captcha = '';

  private clearBrowserHistory = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      email: [this.activatedRoute.snapshot.queryParams.email || '', Validators.required],
    });
  }

  ngOnInit(): void {
    const { queryParams } = this.activatedRoute.snapshot;
    this.clearBrowserHistory = queryParams.clearBrowserHistory;

    this.versionNumber = LatchVersionInfoService.getHumanReadableVersion();
  }

  handleSubmitPreCaptcha(event: Event): boolean {
    event.preventDefault();
    if (this.form.invalid) {
      this.emailInput.nativeElement.focus();
      return false;
    }
    return true;
  }

  handleSubmitPostCaptcha(captchaResponse: string): void {
    this.hasSubmitted = true;

    if (this.form.invalid) {
      this.emailInput.nativeElement.focus();
      this.reCaptcha.reset();
      return;
    }

    this.statusMessage = 'Loading . . .';

    this.authService.forgotPasswordOld(this.form.value.email, captchaResponse, this.clearBrowserHistory).subscribe(
      () => {
        this.reCaptcha.reset();
        this.statusMessage = 'We sent you an email with a link and instructions to get back into your account.';
      },
      (error) => {
        this.reCaptcha.reset();
        const message = AuthService.getPayload(error);
        if (message === ResetPasswordError.UserAccountNotActive) {
          this.statusMessage = 'Sorry, your user account is not active. Please contact support@latch.com.';
        } else if (message === ResetPasswordError.InvalidCaptcha) {
          this.statusMessage = 'There was a problem with Captcha, please try again later.';
        } else {
          this.statusMessage = 'There was a problem resetting your password, please try again later.' +
            ' If the problem persists, contact support@latch.com.';
        }
      });
  }

  handleBack(): void {
    const email = this.form.value.email;
    const queryParams = email !== '' ? { email } : {};
    this.router.navigate(['/login'], { queryParams });
  }

}
