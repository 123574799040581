import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { mockResponse } from '../../../utilities/mock-response';
import { AuthService } from '../../services/auth/auth.service';
import { AccountService } from './account.service';
import { mockAccounts } from '../../../mock-db/accounts';
import { Account } from '../../../models/account';

/**
 * Mock implementation of AccountService returning static data for testing.
 */
@Injectable()
export class MockAccountService extends AccountService {
  accounts: Account[] = _.cloneDeep(mockAccounts);

  constructor(protected authService: AuthService) {
    super(authService);
  }

  getAccounts(): Observable<Account[]> {
    if (!this.accounts$) {
      let accounts = this.accounts;

      // Technically we should just be returning accounts that the current user has access to, but mock data
      // assumes the current user has access to all accounts in the mock DB, so this line is just to catch the
      // scenario where the user is logged out (which should never actually get hit if the user is logged out -
      // but if for some reason it did, it should accurately mimic the real service).
      if (!this.authService.isLoggedIn) {
        accounts = [];
      }

      // See docs for how this provides caching.
      return mockResponse(() => accounts).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }

    return this.accounts$;
  }
}
