import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@latch/latch-web';
import { PropertyManager } from '../../models/property-manager';
import { getFullName } from '../../utilities/presentation';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(user: User | PropertyManager, args?: any): string {
    return getFullName(user.firstName ?? '', user.lastName);
  }

}
