<input
  type="text"
  class="form-control date"
  [(ngModel)]="date"
  placeholder="mm/dd/yy"
  (blur)="handleBlurDate()"
  [disabled]="disableDate"
/>
<ng-container *ngIf="!onlyDate">
  <latch-spacer></latch-spacer>
  <input
    type="text"
    class="form-control time"
    [(ngModel)]="time"
    placeholder="hh:mm"
    (blur)="handleBlurTime()"
    [disabled]="disableTime"
  />
  <select class="meridiem" [(ngModel)]="meridiem" [disabled]="disableTime">
    <option value="AM">am</option>
    <option value="PM">pm</option>
  </select>
</ng-container>
