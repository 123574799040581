import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivityService } from '../../core/services/activity/activity.service';
import { LockService } from '../../core/services/lock/lock.service';
import {
  Activity,
  commentMaxLength,
  commentMinLength,
  courierMaxLength,
  courierMinLength,
  DeliverSelect,
  deliverySelect,
  ImageSource,
  prioritySelect,
  PrioritySelect,
  statusSelect,
  StatusSelect
} from '../../models/activity';
import { Building } from '../../models/building';
import { LockSelect } from '../../models/lock';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';
import { momentToDateTime, getParsedTime, validateDateTime } from '../../utilities/date-time';
import * as moment from 'moment-timezone';
import { LatchConfirmationDialogConfig, LatchDialogRef, LatchDialogService, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { Subject } from 'rxjs';
import { Account } from '../../models/account';
import { SelectedAccountService } from '../../services/selected-account/selected-account.service';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { activityTabs, Tab } from '../../models/tab';
import { getFilledUnits, validateUnits } from '../../utilities/validate-units';

const checkKeys: string[] = [
  'deliveryType',
  'courier',
  'deliveryDateTime',
  'status',
  'priority',
  'comment',
  'units'
];

@Component({
  selector: 'latch-view-activity-log',
  templateUrl: './view-activity-log.component.html',
  styleUrls: ['./view-activity-log.component.scss']
})
export class ViewActivityLogComponent implements OnInit, OnDestroy {
  @ViewChild('cardElement', { read: ElementRef, static: false })
  cardElement!: ElementRef;
  public commentMinLength = commentMinLength;
  public commentMaxLength = commentMaxLength;
  public courierMinLength = courierMinLength;
  public courierMaxLength = courierMaxLength;
  public activityForm = new UntypedFormGroup({
    deliveryType: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    buildingName: new UntypedFormControl({ value: '', disabled: true }),
    operator: new UntypedFormControl({ value: '', disabled: true }),
    lock: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    courier: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.minLength(this.courierMinLength),
      Validators.maxLength(this.courierMaxLength)
    ]),
    deliveryDateTime: new UntypedFormControl({}, Validators.required, validateDateTime),
    status: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    priority: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    comment: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.minLength(this.commentMinLength),
      Validators.maxLength(this.commentMaxLength)
    ]),
    organization: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    units: new UntypedFormArray([], validateUnits())
  });
  public deliverySelect: DeliverSelect[] = deliverySelect;
  public statusSelect: StatusSelect[] = statusSelect;
  public prioritySelect: PrioritySelect[] = prioritySelect;
  public locksSelect!: LockSelect[];
  public isLoading = false;
  public isEditing = false;
  public activity!: Activity;
  public checkKeys: string[] = checkKeys;
  public activityTabs: Tab[] = activityTabs;
  public selectedTab!: string;
  public couriers: string[] = [];
  public ImageSource = ImageSource;

  private activityId!: string;
  private selectedAccount!: Account;
  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private activityService: ActivityService,
    private lockService: LockService,
    private selectedBuildingsService: SelectedBuildingsService,
    private dialog: LatchDialogService,
    private selectedAccountService: SelectedAccountService,
    private errorHandlerService: ErrorHandlerService,
    @Inject(LATCH_DIALOG_DATA) @Optional() private dialogData: string | null,
    @Inject(LatchDialogRef) @Optional() private dialogRef: LatchDialogRef<boolean> | null,
  ) { }

  get activityUnits(): UntypedFormArray {
    return this.activityForm.get('units') as UntypedFormArray;
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.activityId = this.dialogData;
    }
    this.selectTab(this.activityTabs[0].id);
    this.isLoading = true;
    this.selectedAccountService.getSelectedAccount().pipe(
      tap(selectedAccount => this.selectedAccount = selectedAccount),
      switchMap(() => this.selectedBuildingsService.getSelectedBuildings()),
      tap(buildings => this.selectedBuilding = buildings[0]),
      switchMap(() => this.activityService.getCourierNames()),
      tap(couriersResponse => this.couriers = couriersResponse.courierNames),
      switchMap(() => this.lockService.getBuildingLocksWithIntercom(this.selectedBuilding.uuid)),
      tap(locks => this.locksSelect = locks.map(lock => ({
        name: lock.name,
        value: lock.lockUUID
      }))),
      switchMap(() => this.activityService.getActivity(this.selectedBuilding.uuid, this.activityId)),
      takeUntil(this.unsubscribe$)
    ).subscribe(activity => {
      this.activity = activity;
      this.setActivityForm();
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectTab(tabId: string): void {
    this.selectedTab = tabId;
  }

  setCourierValue(courier: string): void {
    this.activityForm.get('courier')?.setValue(courier);
  }

  deleteLog(): void {
    const dialogConfig: LatchConfirmationDialogConfig = {
      hostElementRef: this.cardElement,
      data: {
        messages: ['Are you sure you want to delete the activity log?'],
        confirmText: 'Delete',
        confirmButtonClasses: ['latch-danger'],
        cancelText: 'Cancel',
      }
    };
    this.dialog
      .openConfirmation(dialogConfig)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.isLoading = true;
          this.activityService.deleteLog(this.selectedBuilding.uuid, this.activityId).pipe(
            finalize(() => this.isLoading = false),
            takeUntil(this.unsubscribe$)
          ).subscribe(() => this.handleClose(true),
            error => this.errorHandlerService.handleException(error)
          );
        }
      });
  }

  enterEditMode(): void {
    this.isEditing = true;
    checkKeys.forEach(key => {
      this.activityForm.get(key)?.enable();
    });
  }

  cancelEditMode(): void {
    this.isEditing = false;
    checkKeys.forEach(key => {
      this.activityForm.get(key)?.disable();
    });
    this.setActivityForm();
  }

  saveLog(): void {
    this.isLoading = true;
    this.activityService.updateActivity(this.selectedBuilding.uuid, this.activityId, {
      deliveryType: this.activityForm.value.deliveryType,
      courier: this.activityForm.value.courier,
      deliveryDateTime: this.getTime(this.activityForm.value.deliveryDateTime),
      status: this.activityForm.value.status,
      priority: this.activityForm.value.priority,
      comment: this.activityForm.value.comment || null,
      lockUUID: this.activity.lock.id,
      units: getFilledUnits(this.activityForm.value.units),
      title: ''
    }).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.handleClose(true),
      error => this.errorHandlerService.handleException(error)
    );
  }

  handleClose(closeValue: boolean): void {
    if (this.dialogRef) {
      this.dialogRef.close(closeValue);
    }
  }

  private setActivityForm(): void {
    this.activityForm.get('deliveryType')?.setValue(this.activity.type || this.activity.deliveryType);
    this.activityForm.get('buildingName')?.setValue(this.selectedBuilding.name);
    this.activityForm.get('organization')?.setValue(this.selectedAccount.name);
    this.activityForm.get('operator')?.setValue(this.activity.createdBy.displayName);
    this.activityForm.get('lock')?.setValue(this.activity.lock.id);
    this.activityForm.get('courier')?.setValue(this.activity.courier);
    this.activityForm.get('deliveryDateTime')?.setValue(momentToDateTime(moment(this.activity.deliveryDateTime)));
    this.activityForm.get('status')?.setValue(this.activity.status);
    this.activityForm.get('priority')?.setValue(this.activity.priority);
    this.activityForm.get('comment')?.setValue(this.activity.comment);
    (this.activityForm.get('units') as UntypedFormArray).clear();
    this.activity.units.forEach(unit => (this.activityForm.get('units') as UntypedFormArray).push(
      new UntypedFormGroup({
        id: new UntypedFormControl(unit.id),
        numberOfPackages: new UntypedFormControl(unit.numberOfPackages),
        packageType: new UntypedFormControl(unit.packageType),
        displayName: new UntypedFormControl(unit.displayName)
      })
    ));
  }

  private getTime(timeData: any): number {
    return getParsedTime(timeData, true).valueOf();
  }
}
