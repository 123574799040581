<latch-loading [isActive]="isLoading">
    <div class="page-content">
        <latch-subnav
            headerTitle="Managers"
            [subtitle]="filteredManagers ? '('+filteredManagers.length+')' : ''">
        </latch-subnav>
        <ng-container *ngIf="filteredManagers">
            <div class="no-results" *ngIf="filteredManagers.length === 0 && !isLoading">
              There are no results matching your search.<br>Please try again.
            </div>
            <div class="table-container" *ngIf="filteredManagers.length > 0">
                <latch-table class="latch-table-with-viewport" [data]="datasource" (rowClick)="openManager($event.personUUID)">
                    <latch-table-column field="name" columnTitle="Name" [sortable]="true">
                      <ng-template latchTableCellTemplate let-manager>
                        <div>{{manager | fullName}}</div>
                        <div>Building Manager</div>
                      </ng-template>
                    </latch-table-column>
                    <latch-table-column field="email" columnTitle="Email">
                        <ng-template latchTableCellTemplate let-manager>
                            {{manager.email}}
                        </ng-template>
                      </latch-table-column>
                      <latch-table-column field="phone" columnTitle="Phone">
                        <ng-template latchTableCellTemplate let-manager>
                            {{manager.phoneNumber}}
                        </ng-template>
                      </latch-table-column>
                </latch-table>
            </div>
        </ng-container>
    </div>
</latch-loading>