import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagersFilter } from '../../../models/filter';
import { PropertyManager } from '../../../models/property-manager';
import { naturalSortBy } from '../../../utilities/natural-sort-by';

@Injectable({
  providedIn: 'root'
})
export abstract class ManagerService {

  getFilteredManagers(managers: PropertyManager[], filter: ManagersFilter): PropertyManager[] {
    let filteredManagers = managers.filter(manager => (
      !filter.search ||
      (manager.firstName && manager.firstName.toLowerCase().indexOf(filter.search.toLowerCase()) > -1) ||
      (manager.lastName && manager.lastName.toLowerCase().indexOf(filter.search.toLowerCase()) > -1) ||
      (manager.email && manager.email.toLowerCase().indexOf(filter.search.toLowerCase()) > -1) ||
      (manager.phoneNumber && manager.phoneNumber.toLowerCase().indexOf(filter.search.toLowerCase()) > -1)
    ));
    if (filter.sort.active) {
      filteredManagers = filter.sort.direction === 'asc' ?
        naturalSortBy(filteredManagers, filter.sort.active as keyof PropertyManager).reverse() :
        naturalSortBy(filteredManagers, filter.sort.active as keyof PropertyManager);
    }
    return filteredManagers;
  }

  abstract getPropertyManagers(buildingUUID: string): Observable<PropertyManager[]>;

  abstract getPropertyManagerByPersonUUID(buildingUUID: string, personUUID: string): Observable<PropertyManager>;
}
