import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'latch-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('notificationState', [
      state('active', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate(300)
      ]),
      transition(':leave', [
        animate(300, style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class NotificationComponent {

  constructor(
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  get notificationMessage(): string {
    return this.notificationService.message;
  }

  get messageState(): string {
    return this.notificationMessage ? 'active' : 'inactive';
  }

  get errorHeader(): string {
    return this.notificationService.errorHeader;
  }

  get errorBody(): string {
    return this.notificationService.errorBody;
  }

  get defaultSupportMessage(): boolean {
    return !this.notificationService.disableDefaultMessage;
  }

  get errorState(): string {
    return this.errorBody ? 'active' : 'inactive';
  }

  clearNotificationMessage(): void {
    this.notificationService.clearMessage();
  }

  clearError() {
    this.errorHandlerService.clear();
  }
}
