/**
 * Date Time Utilities
 *
 * Primarily used for start/end time logic.
 */

import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { Observable, of } from 'rxjs';

export enum Meridiem {
  AM = 'AM',
  PM = 'PM'
}

export interface DateTime {
  date?: string;
  time?: string;
  meridiem?: Meridiem;
}

export const DATE_DISPLAY_FORMAT = 'M/D/YY';
export const DATE_PARSE_FORMATS = [
  'M/D/YY',
  'M/D/YYYY',
  'MM/DD/YY',
  'MM/DD/YYYY'
];
export const TIME_DISPLAY_FORMAT = 'h:mm';
export const TIME_PARSE_FORMATS = [
  'h:mm A',
  'hh:mm A',
  'h A',
  'hh A'
];
export const ISO_NO_TZ_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export const getValidTimeZone = (timeZone?: string) => _.includes(moment.tz.names(), timeZone) ?
  timeZone :
  moment.tz.guess();

export const getParsedTime = (dateTime: DateTime, validateTime = false): Moment => {
  const date = moment(dateTime.date, DATE_PARSE_FORMATS, true);
  const time = moment(`${dateTime.time ?? ''} ${dateTime.meridiem ?? ''}`, TIME_PARSE_FORMATS, true);
  if (time.isValid() || !validateTime) {
    return moment(date)
      .hours(time.hours())
      .minutes(time.minutes());
  } else {
    return moment.invalid();
  }
};

export const today = () => moment().startOf('day');

export const momentToDateTime = (moment_: moment.Moment) => {
  const dateTime: DateTime = {
    date: moment_.format(DATE_DISPLAY_FORMAT),
    time: moment_.format(TIME_DISPLAY_FORMAT),
    meridiem: moment_.format('A') === 'AM' ? Meridiem.AM : Meridiem.PM
  };
  return dateTime;
};

export function validateDateTime(control: AbstractControl): Observable<ValidationErrors | null> {
  return of(getParsedTime(control.value, true).isValid() ? null : { invalidDateTime: true });
}

export const isDateTime = (data: any): boolean =>
  _.isObject(data) &&
  Object.keys(data).length === 3 &&
  Object.prototype.hasOwnProperty.call(data, 'date') &&
  Object.prototype.hasOwnProperty.call(data, 'time') &&
  Object.prototype.hasOwnProperty.call(data, 'meridiem');
