import { Component } from '@angular/core';
import { LatchAnalyticsService } from '@latch/latch-web';

@Component({
  selector: 'latch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    // We need this here in order to initlize the analytics service on the common library.
    // If this is addressed in the common library and the service is properly initlized we can removed this.
    private analyticsService: LatchAnalyticsService
  ) {
  }
}
