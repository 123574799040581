export enum LockType {
  ELEVATOR = 'ELEVATOR',
  DOOR = 'DOOR'
}

export enum LockAssignmentStatus {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  NEVER_ASSIGNED = 'NEVER_ASSIGNED'
}

export enum LockDeviceType {
  M = 'M',
  R = 'R',
  C = 'C',
  G = 'G',
  R2 = 'R2'
}

export enum SyncState {
  SyncScheduled = 'SYNC_SCHEDULED',
  SyncingOnline = 'SYNCING_ONLINE',
  SyncedByDevice = 'SYNCED_BY_DEVICE',
  SyncedByMobile = 'SYNCED_BY_MOBILE',
  PendingAdminSync = 'PENDING_ADMIN_SYNC',
  PendingInstall = 'PENDING_INSTALL',
  PendingUserSync = 'PENDING_USER_SYNC'
}

export interface LockSelect {
  name: string;
  value: string;
}

export interface Battery {
  percentage: number;
  voltage: number;
  date: number;
}

export interface SyncTask {
  uuid: string;
  lockUUID: string;
  deadline: number;
  earliestDownlink: number;
  state: SyncState;
}

export interface Lock {
  lockUUID: string;
  buildingUUID: string;
  name: string;
  type: LockType;
  lockState: LockAssignmentStatus;
  deviceType: LockDeviceType;
  earliestDeadlineSyncTaskStatusViews: SyncTask;
  currentBattery?: Battery;
  intercomType?: string;
}

export type UnlockStatus = 'success' | 'error';
