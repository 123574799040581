import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { units } from '../../../mock-db/units';
import { Unit } from '../../../models/unit';
import { mockResponse } from '../../../utilities/mock-response';
import { UnitService } from './unit.service';

@Injectable({
  providedIn: 'root'
})
export class MockUnitService extends UnitService {

  constructor() {
    super();
  }

  getUnits(buildingUUID: string): Observable<Unit[]> {
    return mockResponse(() => units);
  }
}
