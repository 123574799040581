<div class="notification message-type" [@notificationState]="messageState" *ngIf="notificationMessage">
  <!-- placeholder for justify-content: space-between -->
  <div></div>
  <div>{{notificationMessage}}</div>
  <button class="latch-button-text button-dismiss" (click)="clearNotificationMessage()">
    <fa-icon icon="check"></fa-icon>
    Done
  </button>
</div>

<div class="notification error-type" [@notificationState]="errorState" *ngIf="errorBody">
  <div>{{errorHeader}}</div>
  <div class="error-body">
    <span>{{errorBody}}</span>
    <span *ngIf="defaultSupportMessage"> If this problem persists, contact <a href="https://support.latch.com" target="_blank">support</a>.</span>
  </div>
  <div> <!-- We need three divs regardless of whether we show the button because this part of a three-piece layout -->
    <button class="latch-button-text button-dismiss" (click)="clearError()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>
</div>
