import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  constructor(
    private http: HttpClient
  ) { }

  request(
    method: string,
    endpoint: string,
    data?: Record<string, any>,
    headers?: HttpHeaders | { [name: string]: string | string[] },
    search?: HttpParams | { [param: string]: string | string[] }): Observable<HttpResponse<any>> {

    const url = environment.apiBaseUrl + endpoint;
    const body = data ? JSON.stringify(data) : null;
    const httpHeaders = headers instanceof HttpHeaders ?
      headers.set('Content-Type', 'application/json') :
      new HttpHeaders({ ...headers, 'Content-Type': 'application/json' });
    const httpParams = search instanceof HttpParams ?
      search : new HttpParams({ fromObject: search });

    return this.http.request(method, url, {
      headers: httpHeaders,
      params: httpParams,
      body,
      observe: 'response', // get the full response
      withCredentials: true
    });
  }
}
