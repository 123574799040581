<latch-loading [isActive]="isLoading">
  <div class="heading">
    <img src="/assets/images/latch-logo.svg" alt="logo" class="logo" />
  </div>
  <div class="divider"></div>
  <div class="content">
    <h2>{{headerText}}</h2>
    <p>{{messageText}}</p>
  </div>
  <div class="divider"></div>
  <div class="footer">
    <a href="javascript:void(0)" (click)="handleHome()">Home</a>
    <ng-container *ngIf="showLogoutLink">
      | <a href="javascript:void(0)" (click)="handleLogout()">Logout</a>
    </ng-container>
  </div>
</latch-loading>
