import { PaginatedList } from '../models/pagination';

export function paginateList<T>(items: T[], page: number, pageSize: number): PaginatedList<T> {
  const startingSliceIndex: number = (page - 1) * pageSize;
  return {
    page: page,
    pageSize: pageSize,
    totalElements: items.length,
    totalPages: Math.ceil(items.length / pageSize),
    items: items.slice(startingSliceIndex, startingSliceIndex + pageSize)
  };
}
