<div class="info-container" *ngIf="activityUnits.controls.length === 0">
    <div class="units-header">Units</div>
    <div class="no-units" *ngIf="!isEditableValue">No Units</div>
    <button class="add-units" *ngIf="isEditableValue" (click)="addActivityUnits(numberOfUnitsPerAdd || 1)">Add Units</button>
</div>
<ng-container *ngIf="activityUnits.controls.length > 0">
    <div class="units-container" [formGroup]="$any(activityUnits)">
        <div class="units-row">
            <div class="units-element units-header">Units</div>
            <div class="units-element units-header">Items</div>
            <div class="units-element units-header">Package Type</div>
            <div class="units-element units-header"></div>
        </div>
        <div class="units-row" *ngFor="let activityUnit of activityUnits.controls; let i = index" [formGroupName]="i">
            <div class="units-element">
                <div class="unit-selector">
                    <div class="unit-content" [class.disabled]="!isEditableValue" (click)="isEditableValue && setActiveAutocomplete(i)">
                        <div>Unit</div>
                        <div *ngIf="activeAutocomplete !== i">
                            {{$any(activityUnits.value[i]).displayName}}
                        </div> 
                    </div>
                    <div class="unit-autocomplete"
                        [skipFirstOutsideClick]="true"
                        (latchOutsideClick)="setActiveAutocomplete(null)"
                        *ngIf="isEditableValue && units.length > 0 && activeAutocomplete === i">
                        <latch-autocomplete [items]="unitNames" [autofocus]="true" (itemSelected)="setActivityUnit($event,i)"></latch-autocomplete>
                    </div>
                </div>
            </div>
            <div class="units-element">
                <latch-dropdown
                    [data]="numberOfPackagesSelect" 
                    formControlName="numberOfPackages">
                </latch-dropdown>
            </div>
            <div class="units-element">
                <latch-dropdown
                    [data]="unitPackageTypeSelect" 
                    formControlName="packageType">
                </latch-dropdown>
            </div>
            <div class="units-element latch-button-link" *ngIf="clearRows && isEditableValue" (click)="removeActivityUnit(i)">
                Clear
            </div>
        </div>
    </div>
    <div class="buttons-container">
        <button class="latch-button-link" *ngIf="isEditableValue" (click)="addActivityUnits(numberOfUnitsPerAdd || 1)">Add more units</button>
    </div>
</ng-container>
