<latch-loading [isActive]="isLoading">
  <latch-subnav headerTitle="Activity" [subtitle]="totalActivities !== undefined ? '('+totalActivities+')' : ''"
    [actions]="actions">
  </latch-subnav>
  <div class="page-content top-spacing">
    <div class="no-results" *ngIf="activities.length === 0 && !isLoading">
      There are no results matching your search.<br>Please try again.
    </div>
    <div *ngIf="activities.length > 0" latchVirtualScroll [isLoading]="isLoading"
      (load)="nextPage && setPage(nextPage)">
      <latch-table class="latch-table-with-viewport" [data]="datasource" (rowClick)="openViewActivityLog($event.id)">
        <latch-table-column field="title" columnTitle="Log ID">
          <ng-template latchTableCellTemplate let-activity>
            <div class="activity-details" [class.with-image]="activity?.accessLog?.image?.url">
              <div *ngIf="activity?.accessLog?.image?.url" class="activity-image"
                [class.rotate]="activity?.accessLog?.image?.imageSource === ImageSource.LOCK">
                <img [src]="activity?.accessLog?.image?.url">
              </div>
              <div class="activity-info">
                <div class="activity-title">{{activity | activityInfo : 'title'}}</div>
              </div>
            </div>
          </ng-template>
        </latch-table-column>
        <latch-table-column field="priority" columnTitle="Priority">
          <ng-template latchTableCellTemplate let-activity>
            <div [class.priority-high]="activity.priority === ActivityPriority.HIGH">{{activity.priority | titlecase}}
            </div>
          </ng-template>
        </latch-table-column>
        <latch-table-column field="lastUpdatedOn" columnTitle="Time" [sortable]="true">
          <ng-template latchTableCellTemplate let-activity>
            {{activity.lastUpdatedOn | date: 'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </latch-table-column>
        <latch-table-column field="packageType" columnTitle="Package Type">
          <ng-template latchTableCellTemplate let-activity>
            <div class="activity-packages">
              {{activity | activityInfo : 'packages'}}
            </div>
          </ng-template>
        </latch-table-column>
      </latch-table>
    </div>
  </div>
</latch-loading>
