import * as moment from 'moment';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DATE_DISPLAY_FORMAT,
  DATE_PARSE_FORMATS,
  TIME_DISPLAY_FORMAT,
  TIME_PARSE_FORMATS,
  Meridiem,
  DateTime
} from '../../utilities/date-time';

@Component({
  selector: 'latch-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateTimeInputComponent),
    multi: true
  }]
})
export class DateTimeInputComponent implements ControlValueAccessor {
  /** The time input elements (spacer, time and meridiem) are not rendered only allowing to change the date input  */
  @Input() onlyDate = false;
  /** The date input element becomes disabled to disallow manual input of dates, still allows to change time and meridiem */
  @Input() disableDate = false;
  @Input() disableTime = false;

  _date: string | undefined;
  _time: string | undefined;
  _meridiem: Meridiem | undefined;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public propagateChange = (data: any): void => { };

  get date(): string | undefined {
    return this._date;
  }

  set date(date: string | undefined) {
    this._date = date;
    this.emitChange();
  }

  get time(): string | undefined {
    return this._time;
  }

  set time(time: string | undefined) {
    this._time = time;
    this.emitChange();
  }

  get meridiem(): Meridiem | undefined {
    return this._meridiem;
  }

  set meridiem(meridiem: Meridiem | undefined) {
    this._meridiem = meridiem;
    this.emitChange();
  }

  handleBlurDate() {
    const date = moment(this.date, DATE_PARSE_FORMATS, true);
    this.date = date.isValid() ? date.format(DATE_DISPLAY_FORMAT) : this.date;
  }

  public handleBlurTime(): void {
    const time = moment(`${this.time ?? ''} ${this.meridiem ?? ''}`, TIME_PARSE_FORMATS, true);
    this.time = time.isValid() ? time.format(TIME_DISPLAY_FORMAT) : this.time;
  }

  emitChange() {
    this.propagateChange({
      date: this.date,
      time: this.time,
      meridiem: this.meridiem
    });
  }

  writeValue(dateTime: DateTime) {
    dateTime = dateTime || {};
    this._date = dateTime.date;
    this._time = dateTime.time;
    this._meridiem = dateTime.meridiem;
  }

  registerOnChange(fn: (data: any) => void) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(): void { }
}
