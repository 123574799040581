import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  // Ultimately want to share vars with SCSS, but not trivial
  ColorGray4 = '#e6e6e6';
  ColorBlue = '#0089ff';

  // Milliseconds for progress loaders to animate from one value to the next
  DefaultLoaderProgressDuration = 500;

  // Milliseconds between backend requests that need to be sent in serial (BAC-343)
  BackendRequestThrottle = 100;

  // Client-side session expiration in milliseconds (1 day)
  ClientSessionDuration = 60 * 60 * 24 * 1000;
  // Check user's session after this many ms of inactivity (3 hrs)
  InactivityAutoLogoutTimeout = 60 * 60 * 3 * 1000;

  // Number of digits in an NFC serial number
  NFCSerialNumberLength = 12;

  // Time in ms to debounce subscription to form changes
  DebounceTime = 50;
}
