import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LockService } from '../../core/services/lock/lock.service';
import {
  CloseAddActivityModal,
  commentMaxLength,
  commentMinLength,
  courierMaxLength,
  courierMinLength,
  DeliverSelect,
  deliverySelect,
  prioritySelect,
  PrioritySelect,
  statusSelect,
  StatusSelect
} from '../../models/activity';
import { Building } from '../../models/building';
import { LockSelect } from '../../models/lock';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';
import { getParsedTime, momentToDateTime, validateDateTime } from '../../utilities/date-time';
import { ActivityService } from '../../core/services/activity/activity.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { FullNamePipe } from '../../pipes/full-name/full-name.pipe';
import * as moment from 'moment-timezone';
import { SelectedAccountService } from '../../services/selected-account/selected-account.service';
import { Account } from '../../models/account';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { getFilledUnits, validateUnits } from '../../utilities/validate-units';
import { environment } from '../../../environments/environment';
import { LatchDialogRef } from '@latch/latch-web';

@Component({
  selector: 'latch-add-activity-log',
  templateUrl: './add-activity-log.component.html',
  styleUrls: ['./add-activity-log.component.scss']
})
export class AddActivityLogComponent implements OnInit, OnDestroy {
  public commentMinLength = commentMinLength;
  public commentMaxLength = commentMaxLength;
  public courierMinLength = courierMinLength;
  public courierMaxLength = courierMaxLength;
  public activityForm = new UntypedFormGroup({
    deliveryType: new UntypedFormControl(deliverySelect[0].value, Validators.required),
    buildingName: new UntypedFormControl({ value: '', disabled: true }),
    operator: new UntypedFormControl({ value: '', disabled: true }),
    lock: new UntypedFormControl('', Validators.required),
    courier: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(this.courierMinLength),
      Validators.maxLength(this.courierMaxLength)
    ]),
    deliveryDateTime: new UntypedFormControl(momentToDateTime(moment()), Validators.required, validateDateTime),
    status: new UntypedFormControl(statusSelect[0].value, Validators.required),
    priority: new UntypedFormControl(prioritySelect[1].value, Validators.required),
    comment: new UntypedFormControl('', [Validators.minLength(this.commentMinLength), Validators.maxLength(this.commentMaxLength)]),
    organization: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    units: new UntypedFormArray([], validateUnits())
  });
  public deliverySelect: DeliverSelect[] = deliverySelect;
  public statusSelect: StatusSelect[] = statusSelect;
  public prioritySelect: PrioritySelect[] = prioritySelect;
  public locksSelect!: LockSelect[];
  public isLoading = false;
  public couriers: string[] = [];
  public enableDoorUnlock = environment.enableDoorUnlock;

  private selectedAccount!: Account;
  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private activityService: ActivityService,
    private lockService: LockService,
    private selectedBuildingsService: SelectedBuildingsService,
    private authService: AuthService,
    private fullNamePipe: FullNamePipe,
    private selectedAccountService: SelectedAccountService,
    private errorHandlerService: ErrorHandlerService,
    private dialogRef: LatchDialogRef,
  ) { }

  get activityUnits(): UntypedFormArray {
    return this.activityForm.get('units') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedAccountService.getSelectedAccount().pipe(
      tap(selectedAccount => this.selectedAccount = selectedAccount),
      switchMap(() => this.selectedBuildingsService.getSelectedBuildings()),
      tap(buildings => this.selectedBuilding = buildings[0]),
      switchMap(() => this.activityService.getCourierNames()),
      tap(couriersResponse => this.couriers = couriersResponse.courierNames),
      switchMap(() => this.lockService.getBuildingLocksWithIntercom(this.selectedBuilding.uuid)),
      takeUntil(this.unsubscribe$)
    ).subscribe(locks => {
      this.locksSelect = locks.map(lock => ({
        name: lock.name,
        value: lock.lockUUID
      }));
      if (this.selectedBuilding) {
        this.activityForm.get('buildingName')?.setValue(this.selectedBuilding.name);
      }
      if (this.locksSelect.length > 0) {
        this.activityForm.get('lock')?.setValue(this.locksSelect[0].value);
      }
      if (this.authService.currentUser) {
        this.activityForm.get('operator')?.setValue(this.fullNamePipe.transform(this.authService.currentUser));
      }
      this.activityForm.get('organization')?.setValue(this.selectedAccount.name);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCourierValue(courier: string): void {
    this.activityForm.get('courier')?.setValue(courier);
  }

  submit(unlockLock: boolean): void {
    this.isLoading = true;
    const activityRequest = {
      deliveryType: this.activityForm.value.deliveryType,
      lockUUID: this.activityForm.value.lock,
      courier: this.activityForm.value.courier,
      deliveryDateTime: this.getTime(this.activityForm.value.deliveryDateTime),
      status: this.activityForm.value.status,
      priority: this.activityForm.value.priority,
      comment: this.activityForm.value.comment || null,
      units: getFilledUnits(this.activityForm.value.units),
      title: ''
    };
    const closeAddActivityModal: CloseAddActivityModal = { activityCreated: false };
    this.activityService.createActivity(this.selectedBuilding.uuid, activityRequest).pipe(
      switchMap(() => {
        if (unlockLock) {
          return this.lockService.unlockLock(activityRequest.lockUUID).pipe(
            tap(() => closeAddActivityModal.unlockStatus = 'success'),
            catchError(() => closeAddActivityModal.unlockStatus = 'error')
          );
        }
        return of(null);
      }),
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        closeAddActivityModal.activityCreated = true;
        this.dialogRef.close(closeAddActivityModal);
      },
      error => {
        this.dialogRef.close(closeAddActivityModal);
        this.errorHandlerService.handleException(error);
      }
    );
  }

  handleClose() {
    this.dialogRef.close({ activityCreated: false });
  }

  private getTime(timeData: any): number {
    return getParsedTime(timeData, true).valueOf();
  }
}
