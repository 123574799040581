<latch-loading class="container" [class.has-active-operations]="hasActiveOperations"
    [class.focus-mode]="isFocusMode$ | async" [isActive]="isLoading">
    <div class="console" [class.loading]="isLoading">
        <latch-navbar *ngIf="buildings && selectedBuilding && currentUser"
            [selectedContext]="selectedBuilding.name"
            [selectedContextNote]="selectedBuildingNote"
            [showSelectedContextChangeButton]="true"
            [links]="links"
            [profileMenu]="profileMenu">
            <latch-mega-menu (megaMenuOpen)="onMegaMenuOpen($event)">
                <latch-building-selector *ngIf="megaMenuSelectedAccount && megaMenuSelectedBuilding"
                    [buildings]="buildings"
                    [selectedBuilding]="megaMenuSelectedBuilding"
                    (selectedBuildingChange)="selectedBuildingChange($event)"
                    [accounts]="accounts"
                    [selectedAccount]="megaMenuSelectedAccount"
                    (selectedAccountChange)="selectedAccountChange($event)">
                </latch-building-selector>
            </latch-mega-menu>
        </latch-navbar>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</latch-loading>
<latch-notification></latch-notification>
