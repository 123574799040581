import * as _ from 'lodash';
import { IntercomShortInfo, VirtualIntercomShortInfo } from '../models/intercom';
import {
  lockClermont1,
  lock220W2,
  lockInfinite1,
  lockRockefeller1,
  lockClermont2,
  lock220W1,
  lockInfinite2,
  lockRockefeller2,
  lockInfinite3
} from './locks';

export const intercomClermont1: IntercomShortInfo = {
  intercomId: 1,
  lockUUID: lockClermont1.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const intercomClermont2: IntercomShortInfo = {
  intercomId: 2,
  lockUUID: lockClermont2.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const intercom220W1: IntercomShortInfo = {
  intercomId: 3,
  lockUUID: lock220W1.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const intercom220W2: IntercomShortInfo = {
  intercomId: 4,
  lockUUID: lock220W2.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const intercomInfinite1: IntercomShortInfo = {
  intercomId: 5,
  lockUUID: lockInfinite1.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const intercomInfinite2: IntercomShortInfo = {
  intercomId: 6,
  lockUUID: lockInfinite2.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};


export const intercomRockefeller1: IntercomShortInfo = {
  intercomId: 7,
  lockUUID: lockRockefeller1.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const intercomRockefeller2: IntercomShortInfo = {
  intercomId: 8,
  lockUUID: lockRockefeller2.lockUUID,
  parrotSerialNumber: _.uniqueId('parrot')
};

export const virtualIntercom1: VirtualIntercomShortInfo = {
  uuid: 'virtual-intercom-1',
  serialNumber: _.uniqueId('virtual'),
  lockUUID: lockInfinite3.lockUUID,
  url: '',
  nexmoId: _.uniqueId('nexmo'),
  spamCallCount: 0,
};

export const intercoms: IntercomShortInfo[] = [
  intercomClermont1,
  intercomClermont2,
  intercom220W1,
  intercom220W2,
  intercomInfinite1,
  intercomInfinite2,
  intercomRockefeller1,
  intercomRockefeller2
];

export const virtualIntercoms: VirtualIntercomShortInfo[] = [
  virtualIntercom1,
];
