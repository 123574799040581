import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntercomCommon, IntercomShortInfo, VirtualIntercomShortInfo } from '../../../models/intercom';
import { AuthService } from '../auth/auth.service';
import { IntercomService } from './intercom.service';

@Injectable({
  providedIn: 'root'
})
export class HttpIntercomService extends IntercomService {

  constructor(
    private authService: AuthService,
  ) {
    super();
  }

  getBuildingIntercoms(buildingUUID: string): Observable<IntercomCommon[]> {
    return combineLatest([
      this.authService.request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/intercoms`
      }).pipe(
        map(response => (AuthService.getPayload(response) as IntercomShortInfo[]).map(i => ({ ...i, type: 'Intercom' })))
      ),
      this.authService.request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/virtualIntercoms`
      }).pipe(
        map(response => (AuthService.getPayload(response) as VirtualIntercomShortInfo[]).map(i => ({ ...i, type: 'Virtual Intercom' })))
      ),
    ]).pipe(
      map(([intercoms, virtualIntercoms]) => [...intercoms, ...virtualIntercoms])
    );
  }
}
