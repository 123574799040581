import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { intercoms, virtualIntercoms } from '../../../mock-db/intercoms';
import { locks } from '../../../mock-db/locks';
import { IntercomCommon } from '../../../models/intercom';
import { mockResponse } from '../../../utilities/mock-response';
import { IntercomService } from './intercom.service';

@Injectable({
  providedIn: 'root'
})
export class MockIntercomService extends IntercomService {

  constructor() {
    super();
  }

  getBuildingIntercoms(buildingUuid: string): Observable<IntercomCommon[]> {
    return mockResponse(() => {
      const buildingLocks = locks.filter(l => l.buildingUUID === buildingUuid);
      const allIntercoms = [
        ...intercoms.map(i => ({ ...i, type: 'Intercom' })),
        ...virtualIntercoms.map(i => ({ ...i, type: 'Virtual Intercom' }))
      ];
      return allIntercoms.filter(i => buildingLocks.findIndex(bl => bl.lockUUID === i.lockUUID) > -1);
    });
  }
}
