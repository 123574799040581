import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountPermissions } from '../../../models/account';
import { Building, BuildingFeature } from '../../../models/building';
import { AccountService } from '../account/account.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {
  constructor(
    protected authService: AuthService,
    protected accountService: AccountService,
  ) { }

  getAccountUUIDByBuildingUUID(buildingUUID: string): Observable<string> {
    return this.accountService.getAccounts().pipe(
      map(accounts =>
        accounts.find(account => (account.buildings?.findIndex(building => building.uuid === buildingUUID) ?? -1) > -1)?.uuid ?? ''
      ),
    );
  }

  /**
   * Returns a list of buildings for all accounts in portfolio.
   *
   * Provides only a list including UUID and name.
   */
  getBuildings(): Observable<Building[]> {
    return this.accountService.getAccounts().pipe(
      map(accounts => accounts
        .filter(account => account.uuid && account.permissions?.some(p => p === AccountPermissions.ViewDeliveryLogs))
        .reduce<Building[]>((buildings, account) => {
          const ldaBuildings = account.buildings?.filter(b => b.features?.some(f => f === BuildingFeature.DeliveryAssistant)) ?? [];
          buildings.push(...ldaBuildings);
          return buildings;
        }, [])
      ),
    );
  }
}
