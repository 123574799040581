export enum ErrorStatus {
  Application = 'Application error',
  System = 'System error',
  Permission = 'Permissions error'
}

// There are a few code paths where we anticipate potential backend errors and surface them
// appropriately to the user. In most cases, however, it would either be overkill to anticipate
// certain errors, or quite difficult to identify all errors that could occur. For these
// situations, we present the user with the most useful information possible based on the content
// of the error and some hard-coded copy for known backend messages.
export const BackendErrorMessageMap = {
  'user not allowed to access logs': 'This portfolio may not have been configured with the correct permissions.',
  USER_FORBIDDEN: 'You do not have permission to perform this operation. Only portfolio managers can create, edit or delete keys. ' +
    'Only Latch may make changes to Deliveries keys.',
  LOCK_DOES_NOT_EXIST: 'The door you are looking for could not be found.',
  USER_DOES_NOT_EXIST: 'The user you are looking for could not be found.',
  ACCOUNT_DOES_NOT_EXIST: 'The portfolio you are looking for could not be found.',
  ACCESS_DOES_NOT_EXIST: 'The access you are looking for could not be found.',
  INVALID_PHONE_NUMBER: 'The phone number provided is not valid.',
  ACCESS_ALREADY_EXISTS: 'User already has access to this door at this time.',
  CAN_NOT_REVOKE_NON_PERMANENT_ACCESS: 'Daypasses cannot be revoked.',
  BUILDING_NOT_IN_ACCOUNT: 'Hmm, we couldn\'t find that property in this portfolio.',
  LOCK_NOT_IN_ACCOUNT: 'Hmm, we couldn\'t find that door in this property.',
  USER_NOT_IN_ACCOUNT: 'Hmm, we couldn\'t find that user in this property.',
  MFA_INVALID_TOKEN: 'The code you entered is invalid, please try again.',
  MFA_NOT_REQUIRED: 'You cannot verify because Two-Factor Authentication is not enabled on your account',
  MFA_ALREADY_VERIFIED: 'You have already enabled Two-Factor Authentication',
  INVALID_EMAIL_OR_PHONE_NUMBER: 'The email or phone number you provided is invalid.',
  MFA_CREDENTIALS_IN_USE: 'The phone number you provided is already in use.',
  MFA_REQUIRED_FOR_ACCOUNT: 'MFA is required for your portfolio.',
  USER_IS_AN_ACCOUNT_ADMINISTRATOR_ALREADY: 'This user could not be added because they are already a' +
    ' portfolio manager or property manager on this account.',
  UNDELETABLE_LOCK_EXISTS_ON_KEY: 'This door cannot be deleted because it is currently added to one or more keys.'
};

export interface ErrorUserMessage {
  header: string;
  message: string;
}
