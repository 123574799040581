<latch-loading [isActive]="isLoading">
  <latch-subnav headerTitle="Doors"
    [subtitle]="filteredLocksWithIntercom ? '('+filteredLocksWithIntercom.length+')' : ''" [actions]="actions">
  </latch-subnav>
  <div class="page-content top-spacing">
    <ng-container *ngIf="filteredLocksWithIntercom">
      <div class="no-results" *ngIf="filteredLocksWithIntercom.length === 0 && !isLoading">
        There are no results matching your search.<br>Please try again.
      </div>
      <div *ngIf="filteredLocksWithIntercom.length > 0">
        <latch-table class="latch-table-with-viewport" [data]="datasource">
          <latch-table-column field="title" columnTitle="Name" [sortable]="true">
            <ng-template latchTableCellTemplate let-lock>
              <div class="lock-name">{{lock.name}}</div>
              <div class="lock-info">
                <div class="pillsContainer">
                  <div class="pill black">{{ lock.intercomType ?? 'Intercom' }}</div>
                </div>
                <div class="lock-device-type" *ngIf="lock.deviceType">{{lock.deviceType}} Series</div>
              </div>
            </ng-template>
          </latch-table-column>
          <latch-table-column field="battery" columnTitle="Battery">
            <ng-template latchTableCellTemplate let-lock>
              {{lock | lockBattery}}
            </ng-template>
          </latch-table-column>
          <latch-table-column field="action" columnTitle="Action" *ngIf="enableDoorUnlock">
            <ng-template latchTableCellTemplate let-lock>
              <button class="latch-button-link" (click)="unlockLock(lock.lockUUID)">Unlock</button>
            </ng-template>
          </latch-table-column>
        </latch-table>
      </div>
    </ng-container>
  </div>
</latch-loading>
