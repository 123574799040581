import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface RequestParams {
  method: string;
  endpoint: string;
  data?: Record<string, any>;
  headers?: HttpHeaders | { [name: string]: string | string[] };
  search?: HttpParams | { [param: string]: string | string[] };
}

/** Error codes returned from backend when attempting to reset a password ("forgot password"). */
export enum ResetPasswordError {
  /** User has been marked as inactive in our backend (this user should not be logging in). */
  UserAccountNotActive = 'USER_ACCOUNT_NOT_ACTIVE',
  InvalidCaptcha = 'INVALID_CAPTCHA'
}

export declare enum AuthScope {
  ManagerWeb = 'MANAGER_WEB',
  ManagerApp = 'MANAGER_APP',
  ConsumerApp = 'CONSUMER_APP',
  DesktopApp = 'DESKTOP_APP',
  MfaRegistration = 'MFA_REGISTRATION',
  HubApp = 'HUB_APP',
  IntegrationTool = 'INTEGRATION_TOOL',
  DeveloperPortal = 'DEVELOPER_PORTAL',
  IdLatch = 'ID_LATCH'
}

export declare enum AuthClientType {
  Web = 'WEB',
  App = 'APP'
}

export interface ActiveUserSession {
  uuid: string;
  createdAt: Date;
  currentSession: boolean;
  scope: AuthScope;
  clientType: AuthClientType;
  sessionIpAddress: string;
  loginLocation: string;
}
