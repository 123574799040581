import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { SelectedAccountService } from '../../services/selected-account/selected-account.service';

@Injectable({ providedIn: 'root' })
export class LoadAccountGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private selectedAccountService: SelectedAccountService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.selectedAccountService.getSelectedAccount().pipe(
            map((selectedAccount) => !!selectedAccount)
          );
        } else {
          this.router.navigate(['/login']);
          return of(false);
        }
      })
    );
  }
}
