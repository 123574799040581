import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'latch-sign-off-confirmation',
  templateUrl: './sign-off-confirmation.component.html',
  styleUrls: ['./sign-off-confirmation.component.scss']
})
export class SignOffConfirmationComponent implements OnInit, OnDestroy {
  public buildingName!: string;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.pipe(
      filter(params => !!params.buildingName),
      takeUntil(this.unsubscribe$)
    ).subscribe(params => this.buildingName = params.buildingName);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
