import { LatchSortDescriptor } from '@latch/latch-web';

export interface ActivitiesFilter {
  page: number;
  sort: string;
  search: string;
}

export interface ManagersFilter {
  search: string;
  sort: LatchSortDescriptor;
}

export interface LocksFilter {
    search: string;
    sort: LatchSortDescriptor;
}

export const filterDebounce = 300;
