import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Unit } from '../../../models/unit';

@Injectable({
  providedIn: 'root'
})
export abstract class UnitService {

  abstract getUnits(buildingUUID: string): Observable<Unit[]>;
}
