import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth/auth.service';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';

@Component({
  selector: 'latch-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  public isLoading = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  get is403(): boolean {
    const { path } = this.activatedRoute.snapshot.url[0];
    return path === '403';
  }

  get is404(): boolean {
    const { path } = this.activatedRoute.snapshot.url[0];
    return path === '404';
  }

  get headerText() {
    if (this.is404) {
      return 'Page not found';
    }
    if (this.errorHandlerService.currentError) {
      return this.errorHandlerService.currentError.header;
    }
    if (this.is403) {
      return 'Permissions error';
    }
    return 'Unexpected error';
  }

  get messageText() {
    if (this.is404) {
      return 'The page you were looking for could not be found.';
    }
    if (this.errorHandlerService.currentError) {
      return this.errorHandlerService.currentError.message;
    }
    if (this.is403) {
      return 'This account may not have been configured with the correct permissions.';
    }
    return 'An unexpected error occurred.';
  }

  get showLogoutLink(): boolean {
    return this.authService.isLoggedIn;
  }

  handleHome() {
    this.errorHandlerService.clear();
    this.router.navigate(['/']);
  }

  handleLogout() {
    this.errorHandlerService.clear();
    this.isLoading = true;
    this.authService.logout().subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['/login']);
    });
  }

}
