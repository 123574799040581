import {
  Activity,
  ActivityPriority,
  ActivityStatus,
  DeliveryType,
  ImageSource,
  UnlockMethod
} from '../models/activity';
import { getFullName } from '../utilities/presentation';
import {
  lockClermont1,
  lockClermont2,
  lock220W1,
  lock220W2,
  lockInfinite1,
  lockInfinite2,
  lockRockefeller1,
  lockRockefeller2
} from './locks';
import { MOCK_CURRENT_USER } from './users';

const oneDayTime = 1 * 24 * 60 * 60 * 1000;
const todayTime = new Date().getTime();
const yesterdayTime = new Date(todayTime - oneDayTime).getTime();
const lastWeekTime = new Date(todayTime - 7 * oneDayTime).getTime();

export const activityClermont1: Activity = {
  id: 'activity-clermont-1',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: [],
  accessLog: {
    uuid: 'access-log-activity-clermont-1',
    method: UnlockMethod.BLE,
    image: {
      url: 'http://api-dev.latchaccess.com/v2/lock/74f9a793-dfcc-495c-b3db-ddd13bed040b/access-logs/images/1488827847-1488857534226.jpg',
      imageSource: ImageSource.LOCK
    }
  }
};

export const activityClermont2: Activity = {
  id: 'activity-clermont-2',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont3: Activity = {
  id: 'activity-clermont-3',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont4: Activity = {
  id: 'activity-clermont-4',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont5: Activity = {
  id: 'activity-clermont-5',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont6: Activity = {
  id: 'activity-clermont-6',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont7: Activity = {
  id: 'activity-clermont-7',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont8: Activity = {
  id: 'activity-clermont-8',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont9: Activity = {
  id: 'activity-clermont-9',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont10: Activity = {
  id: 'activity-clermont-10',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont11: Activity = {
  id: 'activity-clermont-11',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont12: Activity = {
  id: 'activity-clermont-12',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont13: Activity = {
  id: 'activity-clermont-13',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont14: Activity = {
  id: 'activity-clermont-14',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont15: Activity = {
  id: 'activity-clermont-15',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont16: Activity = {
  id: 'activity-clermont-16',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont17: Activity = {
  id: 'activity-clermont-17',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont18: Activity = {
  id: 'activity-clermont-18',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont19: Activity = {
  id: 'activity-clermont-19',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont20: Activity = {
  id: 'activity-clermont-20',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont21: Activity = {
  id: 'activity-clermont-21',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont1.lockUUID,
    displayName: lockClermont1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityClermont22: Activity = {
  id: 'activity-clermont-22',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockClermont2.lockUUID,
    displayName: lockClermont2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activity220W1: Activity = {
  id: 'activity-220W-1',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lock220W1.lockUUID,
    displayName: lock220W1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: [],
  accessLog: {
    uuid: 'access-log-activity-220W-1',
    method: UnlockMethod.MKO,
    image: {
      url: 'http://api-dev.latchaccess.com/v2/lock/74f9a793-dfcc-495c-b3db-ddd13bed040b/access-logs/images/1488827847-1488857534226.jpg',
      imageSource: ImageSource.LOCK
    }
  }
};

export const activity220W2: Activity = {
  id: 'activity-220W-2',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lock220W2.lockUUID,
    displayName: lock220W2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityInfinite1: Activity = {
  id: 'activity-infinite-1',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockInfinite1.lockUUID,
    displayName: lockInfinite1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: [],
  accessLog: {
    uuid: 'access-log-activity-infinite-1',
    method: UnlockMethod.NFC,
    image: {
      url: 'http://api-dev.latchaccess.com/v2/lock/160454F7-C6CA-4D35-BE8F-CF22011A8329/access-logs/images/1487952832-1487953015256.jpg',
      imageSource: ImageSource.LOCK
    }
  }
};

export const activityInfinite2: Activity = {
  id: 'activity-infinite-2',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockInfinite2.lockUUID,
    displayName: lockInfinite2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activityRockefeller1: Activity = {
  id: 'activity-rockefeller-1',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockRockefeller1.lockUUID,
    displayName: lockRockefeller1.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: yesterdayTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: [],
  accessLog: {
    uuid: 'access-log-activity-rockefeller-1',
    method: UnlockMethod.Passcode,
    image: {
      url: 'http://api-dev.latchaccess.com/v2/lock/74f9a793-dfcc-495c-b3db-ddd13bed040b/access-logs/images/1488827847-1488857534226.jpg',
      imageSource: ImageSource.LOCK
    }
  }
};

export const activityRockefeller2: Activity = {
  id: 'activity-Rockefeller-2',
  deliveryType: DeliveryType.PACKAGE,
  lock: {
    id: lockRockefeller2.lockUUID,
    displayName: lockRockefeller2.name
  },
  courier: 'UPS',
  deliveryDateTime: lastWeekTime,
  comment: '',
  status: ActivityStatus.PENDING,
  priority: ActivityPriority.LOW,
  createdOn: lastWeekTime,
  lastUpdatedOn: todayTime,
  createdBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  lastUpdatedBy: {
    id: MOCK_CURRENT_USER.uuid,
    displayName: getFullName(MOCK_CURRENT_USER.firstName ?? '', MOCK_CURRENT_USER.lastName)
  },
  units: []
};

export const activities: Activity[] = [
  activityClermont1,
  activityClermont2,
  activityClermont3,
  activityClermont4,
  activityClermont5,
  activityClermont6,
  activityClermont7,
  activityClermont8,
  activityClermont9,
  activityClermont10,
  activityClermont11,
  activityClermont12,
  activityClermont13,
  activityClermont14,
  activityClermont15,
  activityClermont16,
  activityClermont17,
  activityClermont18,
  activityClermont19,
  activityClermont20,
  activityClermont21,
  activityClermont22,
  activity220W1,
  activity220W2,
  activityInfinite1,
  activityInfinite2,
  activityRockefeller1,
  activityRockefeller2
];
