import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  LatchAnalyticsService,
  LatchDatasource,
  LatchDialogConfig,
  LatchDialogService,
  LatchNavAction,
  LatchNavbarStateService,
  LatchSortDescriptor
} from '@latch/latch-web';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { AddActivityLogComponent } from '../../components/add-activity-log/add-activity-log.component';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { ViewActivityLogComponent } from '../../components/view-activity-log/view-activity-log.component';
import { ActivityService } from '../../core/services/activity/activity.service';
import { ActivityList, ActivityPriority, CloseAddActivityModal, ImageSource } from '../../models/activity';
import { Building } from '../../models/building';
import { ActivitiesFilter, filterDebounce } from '../../models/filter';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';

@Component({
  selector: 'latch-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public filter: ActivitiesFilter = { page: 1, sort: '', search: '' };
  public nextPage: number | null = null;
  public activities: ActivityList[] = [];
  public totalActivities!: number;
  public actions: LatchNavAction[] = [
    {
      id: 'add-delivery-log-id',
      name: 'Add delivery log',
      clickHandler: (): void => this.openAddActivityLog()
    }
  ];
  public datasource = new LatchDatasource<ActivityList>({});
  public ActivityPriority = ActivityPriority;
  public ImageSource = ImageSource;

  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private acitivityService: ActivityService,
    private selectedBuildingsService: SelectedBuildingsService,
    private latchNavbarStateService: LatchNavbarStateService,
    private analyticsService: LatchAnalyticsService,
    private dialog: LatchDialogService
  ) { }

  ngOnInit(): void {
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(buildings => {
      this.selectedBuilding = buildings[0];
      this.checkSortChange();
      this.setPage(1);
    });
    this.latchNavbarStateService.searchControl.valueChanges.pipe(
      debounceTime(filterDebounce),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(search => this.setFilterSearch(search));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.latchNavbarStateService.searchControl.setValue('');
    this.dialog.close();
  }

  private openAddActivityLog(): void {
    this.analyticsService.track('LDA Open Add Delivery Log Pop up', {
      'Building Name': this.selectedBuilding.name
    });
    this.dialog.open<AddActivityLogComponent, LatchDialogConfig, CloseAddActivityModal>(AddActivityLogComponent, {
      width: '810px',
      maxWidth: '100vw',
      height: 'calc(100% - 100px)'
    }).afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((closeInfo) => this.handleAddActivityModalClose(closeInfo as CloseAddActivityModal));
  }

  setPage(page: number) {
    this.filter.page = page;
    this.getActivities(this.selectedBuilding.uuid, this.filter);
  }

  openViewActivityLog(id: string): void {
    this.dialog.open<ViewActivityLogComponent, string, boolean>(ViewActivityLogComponent, {
      data: id,
      width: '810px',
      maxWidth: '100vw',
      height: 'calc(100% - 100px)'
    }).afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((refresh) => {
      if (refresh) {
        this.setPage(1);
      }
    });
  }

  private handleAddActivityModalClose(closeInfo: CloseAddActivityModal): void {
    if (closeInfo.unlockStatus) {
      if (closeInfo.unlockStatus === 'success') {
        this.openUnlockSuccessDialog();
      } else {
        this.openUnlockErrorDialog();
      }
    }
    if (closeInfo.activityCreated) {
      this.setPage(1);
    }
  }

  private openUnlockSuccessDialog(): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'success',
        title: 'Door unlocked',
        message: '',
        autoClose: 5000
      },
      width: '100%',
      maxWidth: '470px',
    });
  }

  private openUnlockErrorDialog(): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'error',
        title: 'Unlock failed',
        message: 'Please try again. If this problem persists, contact the property manager.',
        autoClose: 8000
      },
      width: '100%',
      maxWidth: '470px',
    });
  }

  private checkSortChange(): void {
    this.datasource.sortChange().pipe(
      distinctUntilChanged(),
      filter(sort => !!sort.active),
      takeUntil(this.unsubscribe$)
    ).subscribe(sort => {
      if (sort.direction) {
        this.filter.sort = this.getSortValue(sort);
      } else {
        this.filter.sort = '';
      }
      this.setPage(1);
    });
  }

  private getSortValue(sort: LatchSortDescriptor): string {
    return `${sort.active}:${sort.direction.toUpperCase()}`;
  }

  private setFilterSearch(search: string): void {
    this.filter.search = search;
    this.setPage(1);
  }

  private getActivities(buildingUuid: string, activitiesFilter: ActivitiesFilter): void {
    this.isLoading = true;
    this.acitivityService.getActivities(buildingUuid, activitiesFilter).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(activitiesListReponse => {
      this.nextPage = activitiesListReponse.metadata.page < activitiesListReponse.metadata.totalPages ?
        activitiesListReponse.metadata.page + 1 : null;
      this.totalActivities = activitiesListReponse.metadata.totalElements;
      if (activitiesFilter.page === 1) {
        this.activities = [];
      }
      if (activitiesListReponse.elements && activitiesListReponse.elements.length > 0) {
        activitiesListReponse.elements.forEach(a => this.activities.push(a));
      }
      this.datasource.set(this.activities);
    });
  }

}
