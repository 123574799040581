import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import {
  LatchAnalyticsModule,
  LatchCardModule,
  LatchDialogModule,
  LatchDropdownModule,
  LatchIconModule,
  LatchIconService,
  LatchListModule,
  LatchLoadingModule,
  LatchLogModule,
  LatchNavbarModule,
  LatchTableModule,
  LATCH_LOG_ENDPOINT_BLACKLIST
} from '@latch/latch-web';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ConsoleComponent } from './pages/console/console.component';
import { FullNamePipe } from './pipes/full-name/full-name.pipe';
import { AuthService } from './core/services/auth/auth.service';
import { AccountService } from './core/services/account/account.service';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { VirtualScrollDirective } from './directives/virtual-scroll/virtual-scroll.directive';
import { AddActivityLogComponent } from './components/add-activity-log/add-activity-log.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { StatusNamePipe } from './pipes/status-name/status-name.pipe';
import { ViewActivityLogComponent } from './components/view-activity-log/view-activity-log.component';
import { ChangesCountPipe } from './pipes/changes-count/changes-count.pipe';
import { ActivityUnitsComponent } from './components/activity-units/activity-units.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { OutsideClickDirective } from './directives/outside-click/outside-click.directive';
import { AccountComponent } from './pages/account/account.component';
import { SupportComponent } from './pages/support/support.component';
import { RecentLoginsPageComponent } from './pages/recent-logins-page/recent-logins-page.component';
import { ActiveSessionsPageComponent } from './pages/active-sessions-page/active-sessions-page.component';
import { ActiveSessionsPipe } from './pipes/active-sessions/active-sessions.pipe';
import { ActivityInfoPipe } from './pipes/activity-info/activity-info.pipe';
import { ManagersComponent } from './pages/managers/managers.component';
import { ManangerDetailsComponent } from './pages/mananger-details/mananger-details.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { AppcuesService } from './services/appcues/appcues.service';
import { DoorsComponent } from './pages/doors/doors.component';
import { LockBatteryPipe } from './pipes/lock-battery/lock-battery.pipe';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationComponent } from './components/notification/notification.component';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { SignOffConfirmationComponent } from './pages/sign-off-confirmation/sign-off-confirmation.component';

const DEFAULT_LOG_ENDPOINT_BLACKLIST = ['/web/v1/operations'];
const APPLICATION_NAME = 'Latch-LDAConsole';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ErrorPageComponent,
    ConsoleComponent,
    FullNamePipe,
    ActivitiesComponent,
    VirtualScrollDirective,
    AddActivityLogComponent,
    DateTimeInputComponent,
    SpacerComponent,
    StatusNamePipe,
    ViewActivityLogComponent,
    ChangesCountPipe,
    ActivityUnitsComponent,
    AutocompleteComponent,
    OutsideClickDirective,
    AccountComponent,
    SupportComponent,
    RecentLoginsPageComponent,
    ActiveSessionsPageComponent,
    ActiveSessionsPipe,
    ActivityInfoPipe,
    ManagersComponent,
    ManangerDetailsComponent,
    TabsComponent,
    DoorsComponent,
    LockBatteryPipe,
    NotificationComponent,
    MessageDialogComponent,
    SignOffConfirmationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot(environment.useMocks),
    RecaptchaFormsModule,
    RecaptchaModule,
    LatchLoadingModule,
    LatchCardModule,
    LatchListModule,
    LatchNavbarModule,
    LatchLogModule.forRoot({
      enableLogging: environment.enableLogging,
      datadog: environment.datadogToken ? {
        datadogToken: environment.datadogToken,
        environmentName: environment.name,
        service: 'lda-client-portal'
      } : undefined,
    }),
    LatchAnalyticsModule.forRoot({
      enableAnalytics: environment.enableAnalytics ?? false,
      environmentName: environment.name,
      heap: environment.heapApplicationId ? {
        applicationId: environment.heapApplicationId,
        client: APPLICATION_NAME,
      } : undefined,
    }),
    LatchDropdownModule,
    LatchTableModule,
    LatchIconModule,
    LatchDialogModule
  ],
  providers: [
    FullNamePipe,
    DatePipe,
    { provide: LATCH_LOG_ENDPOINT_BLACKLIST, useValue: DEFAULT_LOG_ENDPOINT_BLACKLIST }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private appcuesService: AppcuesService,
    private icon: LatchIconService
  ) {
    this.accountService.initialize();
    this.authService.initialize();
    this.appcuesService.initialize();
    this.icon.library.addIcons(faCheck);
    this.icon.library.addIcons(faTimes);
  }
}
