import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  LatchAnalyticsService,
  LatchDatasource,
  LatchDialogConfig,
  LatchDialogService,
  LatchNavAction,
  LatchNavbarStateService
} from '@latch/latch-web';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { LockService } from '../../core/services/lock/lock.service';
import { Building } from '../../models/building';
import { filterDebounce, LocksFilter } from '../../models/filter';
import { Lock } from '../../models/lock';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';
import { CloseAddActivityModal } from '../../models/activity';
import { environment } from '../../../environments/environment';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { AddActivityLogComponent } from 'src/app/components/add-activity-log/add-activity-log.component';

@Component({
  selector: 'latch-doors',
  templateUrl: './doors.component.html',
  styleUrls: ['./doors.component.scss']
})
export class DoorsComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public filter: LocksFilter = { search: '', sort: { active: '', direction: '' } };
  public locksWithIntercom!: Lock[];
  public filteredLocksWithIntercom!: Lock[];
  public actions: LatchNavAction[] = [
    {
      id: 'add-delivery-log-id',
      name: 'Add delivery log',
      clickHandler: (): void => this.openAddActivityLog()
    }
  ];
  public datasource = new LatchDatasource<Lock>({});
  public enableDoorUnlock = environment.enableDoorUnlock;

  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private lockService: LockService,
    private latchNavbarStateService: LatchNavbarStateService,
    private analyticsService: LatchAnalyticsService,
    private dialog: LatchDialogService
  ) { }

  ngOnInit(): void {
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(buildings => {
      this.selectedBuilding = buildings[0];
      this.checkSortChange();
      this.getLocksWithIntercom();
    });
    this.latchNavbarStateService.searchControl.valueChanges.pipe(
      debounceTime(filterDebounce),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(search => this.setFilterSearch(search));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.latchNavbarStateService.searchControl.setValue('');
    this.dialog.close();
  }

  private openAddActivityLog(): void {
    this.analyticsService.track('LDA Open Add Delivery Log Pop up', {
      'Building Name': this.selectedBuilding.name
    });
    this.dialog.open<AddActivityLogComponent, LatchDialogConfig, CloseAddActivityModal>(AddActivityLogComponent, {
      width: '810px',
      maxWidth: '100vw',
      height: 'calc(100% - 100px)'
    }).afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((closeInfo) => this.handleAddActivityModalClose(closeInfo as CloseAddActivityModal));
  }

  unlockLock(lockUUID: string): void {
    this.isLoading = true;
    this.lockService.unlockLock(lockUUID).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.openUnlockSuccessDialog(),
      () => this.openUnlockErrorDialog()
    );
  }

  private handleAddActivityModalClose(closeInfo: CloseAddActivityModal): void {
    if (closeInfo.unlockStatus) {
      if (closeInfo.unlockStatus === 'success') {
        this.openUnlockSuccessDialog();
      } else {
        this.openUnlockErrorDialog();
      }
    }
  }

  private openUnlockSuccessDialog(): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'success',
        title: 'Door unlocked',
        message: '',
        autoClose: 5000
      },
      width: '100%',
      maxWidth: '470px',
    });
  }

  private openUnlockErrorDialog(): void {
    this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'error',
        title: 'Unlock failed',
        message: 'Please try again. If this problem persists, contact the property manager.',
        autoClose: 5000
      },
      width: '100%',
      maxWidth: '470px',
    });
  }

  private getLocksWithIntercom(): void {
    this.isLoading = true;
    this.lockService.getBuildingLocksWithIntercom(this.selectedBuilding.uuid).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(locksWithIntercom => {
      this.locksWithIntercom = locksWithIntercom;
      this.setFilteredLocksWithIntercom();
    });
  }

  private setFilteredLocksWithIntercom(): void {
    this.filteredLocksWithIntercom = this.lockService.getFilteredLocks(this.locksWithIntercom, this.filter);
    this.datasource.set(this.filteredLocksWithIntercom);
  }

  private checkSortChange(): void {
    this.datasource.sortChange().pipe(
      distinctUntilChanged(),
      filter(sort => !!sort.active),
      takeUntil(this.unsubscribe$)
    ).subscribe(sort => {
      if (sort.direction) {
        this.filter.sort = sort;
      } else {
        this.filter.sort = { active: '', direction: '' };
      }
      this.setFilteredLocksWithIntercom();
    });
  }

  private setFilterSearch(search: string): void {
    this.filter.search = search;
    this.setFilteredLocksWithIntercom();
  }

}
