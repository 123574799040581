import { AccountPermissions } from '../models/account';
import { Building, BuildingFeature, PmsSource } from '../models/building';
import * as moment from 'moment-timezone';

export const building150Claremont: Building = {
  uuid: 'BUILDING-150-CLAREMONT-UUID',
  name: '150 Claremont',
  features: [
    BuildingFeature.DoorcodeSuppression,
    BuildingFeature.Intercom,
    BuildingFeature.RentPayments,
    BuildingFeature.SmartHome,
    BuildingFeature.SmartHomeNest,
    BuildingFeature.DeliveryAssistant
  ],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '150 Claremont',
    city: 'New York',
    state: 'NY',
    country: 'USA',
    postalCode: '10003'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess(),
  pmsSource: PmsSource.REALPAGE,
  pmsUpdateTime: moment().startOf('day').toDate(),
};

export const building220w107: Building = {
  uuid: 'BUILDING-220-W-107TH-UUID',
  name: '220 W 107th',
  features: [
    BuildingFeature.DeliveryAssistant
  ],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '220 West 107th St',
    city: 'New York',
    state: 'NY',
    country: 'USA',
    postalCode: '10010'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess()
};

export const building9InfiniteLoop: Building = {
  uuid: 'BUILDING-9-INFINITE-LOOP-UUID',
  name: '9 Infinite Loop',
  features: [
    BuildingFeature.DeliveryAssistant
  ],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '9 Infinite Loop',
    city: 'Cupertino',
    state: 'CA',
    country: 'USA',
    postalCode: '95014'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess(),
  pmsSource: PmsSource.YARDI,
  pmsUpdateTime: moment().startOf('day').toDate(),
};

export const buildingRockefellerCenter: Building = {
  uuid: 'ROCKEFELLER-CENTER-UUID',
  name: 'Rockefeller Center',
  features: [
    BuildingFeature.Commercial,
    BuildingFeature.DeliveryAssistant
  ],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '45 Rockefeller Plz',
    city: 'New York',
    state: 'NY',
    country: 'USA',
    postalCode: '10111-0100'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess(),
  pmsSource: PmsSource.REALPAGE,
  pmsUpdateTime: moment().startOf('day').toDate(),
};

export const building100Moffett: Building = {
  uuid: 'BUILDING-100-MOFFETT-UUID',
  name: '100 Moffett Blvd',
  features: [BuildingFeature.DoorcodeSuppression],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '100 Moffett Blvd',
    city: 'Mountain View',
    state: 'CA',
    country: 'USA',
    postalCode: '94043'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess()
};

export const building85Attorney: Building = {
  uuid: 'BUILDING-85-ATTORNEY-UUID',
  name: '85 Attorney St',
  features: [],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '85 Attorney St',
    city: 'New York',
    state: 'NY',
    country: 'USA',
    postalCode: '10002'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess(),
  pmsSource: PmsSource.REALPAGE,
  pmsUpdateTime: moment().startOf('day').toDate(),
};

export const buildingRockefellerCenter2: Building = {
  uuid: 'ROCKEFELLER-CENTER-UUID',
  name: 'Rockefeller Center',
  features: [
    BuildingFeature.Commercial,
  ],
  permissions: [
    AccountPermissions.ManageBuildings,
    AccountPermissions.ViewDeliveryLogs
  ],
  address: {
    line1: '45 Rockefeller Plz',
    city: 'New York',
    state: 'NY',
    country: 'USA',
    postalCode: '10111-0100'
  },
  technician: {
    name: 'Latch Support',
    email: 'support@latch.com',
    phone: '5555555555'
  },
  timezone: moment.tz.guess(),
  pmsSource: PmsSource.REALPAGE,
  pmsUpdateTime: moment().startOf('day').toDate(),
};

export const mockBuildings: Building[] = [
  building150Claremont,
  building220w107,
  building9InfiniteLoop,
  buildingRockefellerCenter,
  building100Moffett,
  building85Attorney,
  buildingRockefellerCenter2
];
