import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Building } from '../../models/building';
import { arrayOf } from '../../utilities/array-of';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { getValidTimeZone } from '../../utilities/date-time';
import { SelectedAccountService } from '../selected-account/selected-account.service';

@Injectable({ providedIn: 'root' })
export class SelectedBuildingsService {

  public selectedBuildings: Building[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private selectedAccountService: SelectedAccountService,
  ) { }

  getSelectedBuildings(): Observable<Building[]> {
    const selectedBuildingsUUIDs$ = this.activatedRoute.queryParams.pipe(
      map((queryParams) => arrayOf(queryParams.building)),
      distinctUntilChanged(
        (oldBuildings, newBuildings) => _.isEqual(oldBuildings.sort(), newBuildings.sort())
      ));

    return combineLatest([this.selectedAccountService.getSelectedAccount(), selectedBuildingsUUIDs$]).pipe(
      switchMap(([{ buildings }, selectedBuildingsUUIDs]): Observable<Building[]> => {
        if (!buildings || (buildings?.length ?? 0) === 0) {
          // Todo: throw error that UI can surface to user
          return EMPTY;
        }

        const selectedBuildings = selectedBuildingsUUIDs
          .map((buildingUUID) => _.find(buildings, { uuid: buildingUUID }))
          .filter((building) => !!building);

        // Return valid selected buildings if we have them.
        if (selectedBuildings.length > 0) {
          return of(selectedBuildings) as Observable<Building[]>;
        }

        // Redirect to the first available building, triggering the observable chain to emit again
        // (since selectedBuildingsUUIDs$ will emit)
        const selectedBuilding = buildings[0];
        // Couldn't figure out how to get location.pathname from Angular
        const path = this.router.url.split('?')[0];
        this.router.navigate([path], {
          queryParams: {
            building: selectedBuilding.uuid
          },
          replaceUrl: true
        });

        return EMPTY;
      }),
      tap((buildings: Building[]) => {
        if (buildings.length > 0) {
          moment.tz.setDefault(getValidTimeZone(buildings[0].timezone));
        }
      }),
      tap((selectedBuildings: Building[]) => this.selectedBuildings = selectedBuildings)
    );
  }

}
