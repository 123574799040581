import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropertyManager } from '../../../models/property-manager';
import { AuthService } from '../auth/auth.service';
import { ManagerService } from './manager.service';

@Injectable({
  providedIn: 'root'
})
export class HttpManagerService extends ManagerService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getPropertyManagers(buildingUUID: string): Observable<PropertyManager[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/propertyManagers`
    }).pipe(
      map(response => AuthService.getPayload(response).propertyManagers)
    );
  }

  getPropertyManagerByPersonUUID(buildingUUID: string, personUUID: string): Observable<PropertyManager> {
    return this.getPropertyManagers(buildingUUID).pipe(
      map(propertyManagers => {
        const propertyManager = propertyManagers.find(manager => manager.personUUID === personUUID);
        if (!propertyManager) {
          throw new Error('Property manager not found');
        }
        return propertyManager;
      })
    );
  }
}
