import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '../../models/tab';

@Component({
  selector: 'latch-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() public tabs: Tab[] = [];
  @Input() public selectedTab!: string;
  @Output() public tabClicked = new EventEmitter<string>();

}
