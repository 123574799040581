import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Unit } from '../../../models/unit';
import { AuthService } from '../auth/auth.service';
import { UnitService } from './unit.service';

@Injectable({
  providedIn: 'root'
})
export class HttpUnitService extends UnitService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getUnits(buildingUUID: string): Observable<Unit[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/units`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError((error) => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if ((error instanceof HttpErrorResponse) && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }
}
