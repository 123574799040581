import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LatchDatasource, LatchNavbarStateService } from '@latch/latch-web';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { ManagerService } from '../../core/services/manager/manager.service';
import { Building } from '../../models/building';
import { filterDebounce, ManagersFilter } from '../../models/filter';
import { PropertyManager } from '../../models/property-manager';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';

@Component({
  selector: 'latch-managers',
  templateUrl: './managers.component.html',
  styleUrls: ['./managers.component.scss']
})
export class ManagersComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public filter: ManagersFilter = { search: '', sort: { active: '', direction: '' } };
  public filteredManagers!: PropertyManager[];
  public managers!: PropertyManager[];
  public datasource = new LatchDatasource<PropertyManager>({});

  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private managerService: ManagerService,
    private router: Router,
    private latchNavbarStateService: LatchNavbarStateService
  ) { }

  ngOnInit(): void {
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(buildings => {
      this.selectedBuilding = buildings[0];
      this.checkSortChange();
      this.getManagers();
    });
    this.latchNavbarStateService.searchControl.valueChanges.pipe(
      debounceTime(filterDebounce),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(search => this.setFilterSearch(search));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.latchNavbarStateService.searchControl.setValue('');
  }

  openManager(personUUID: string): void {
    this.router.navigate([`/console/manager-details/${personUUID}`], { queryParamsHandling: 'preserve' });
  }

  private checkSortChange(): void {
    this.datasource.sortChange().pipe(
      distinctUntilChanged(),
      filter(sort => !!sort.active),
      takeUntil(this.unsubscribe$)
    ).subscribe(sort => {
      if (sort.direction) {
        this.filter.sort = sort;
      } else {
        this.filter.sort = { active: '', direction: '' };
      }
      this.setFilteredManagers();
    });
  }

  private setFilterSearch(search: string): void {
    this.filter.search = search;
    this.setFilteredManagers();
  }

  private setFilteredManagers(): void {
    this.filteredManagers = this.managerService.getFilteredManagers(this.managers, this.filter);
    this.datasource.set(this.filteredManagers);
  }

  private getManagers(): void {
    this.isLoading = true;
    this.managerService.getPropertyManagers(this.selectedBuilding.uuid).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(managers => {
      this.managers = managers;
      this.setFilteredManagers();
    });
  }

}
