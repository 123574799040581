import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { locks } from '../../../mock-db/locks';
import { Lock } from '../../../models/lock';
import { mockResponse } from '../../../utilities/mock-response';
import { IntercomService } from '../intercom/intercom.service';
import { LockService } from './lock.service';

@Injectable({
  providedIn: 'root'
})
export class MockLockService extends LockService {

  constructor(
    private intercomService: IntercomService
  ) {
    super();
  }

  getBuildingLocksWithIntercom(buildingUuid: string): Observable<Lock[]> {
    return this.intercomService.getBuildingIntercoms(buildingUuid).pipe(
      switchMap(buildingIntercoms => of(locks.filter(l => l.buildingUUID === buildingUuid)).pipe(
        map(lcks => lcks.filter(l => buildingIntercoms.findIndex(i => i.lockUUID === l.lockUUID) > -1)),
        map(lcks => lcks.map(l => ({ ...l, intercomType: buildingIntercoms.find(i => i.lockUUID === l.lockUUID)?.type ?? '' }))),
      ))
    );
  }

  getBuildingLocks(buildingUuid: string): Observable<Lock[]> {
    return mockResponse(() => locks.filter(l => l.buildingUUID === buildingUuid));
  }

  unlockLock(lockUUID: string): Observable<any> {
    return mockResponse(() => {
      const unlockStates = ['success', 'error'];
      const unlockState = unlockStates[Math.floor(Math.random() * unlockStates.length)];
      if (unlockState === 'error') {
        throw new Error('Unlock was not successful');
      }
      return unlockState;
    });
  }
}
