import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'latch-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() public items!: string[];
  @Input() public autofocus = false;
  @Input() public placeholder = '';
  @Input() public initialValue = '';
  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.search?.disable();
    } else {
      this.search?.enable();
    }
  }
  @Output() public valueChanged = new EventEmitter<string>();
  @Output() public itemSelected = new EventEmitter<string>();
  public search = new UntypedFormControl('');
  public filteredItems: string[] = [];
  public searchAttributeValue = Math.random().toString(18);

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    if (this.initialValue) {
      this.setSearchValue(this.initialValue);
    }
    this.search.valueChanges.subscribe(search => {
      this.filterItems(search);
      this.valueChanged.emit(search);
    });
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.setFocus(this.searchAttributeValue);
    }
  }

  clearSearch(): void {
    this.search.setValue('');
  }

  selectItem(item: string): void {
    this.setSearchValue(item);
    this.filteredItems = [];
    this.itemSelected.emit(item);
    this.setFocus(this.searchAttributeValue);
  }

  handleSearchKeyDown(event: any): void {
    if (event.keyCode === 40) {
      event.preventDefault();
      this.setFocus('0');
    }
  }

  handleElementKeyDown(event: any, index: number): void {
    switch (event.keyCode) {
      case 13: {
        event.preventDefault();
        this.selectItem(this.filteredItems[index]);
        break;
      }
      case 40: {
        event.preventDefault();
        if (this.filteredItems[index + 1]) {
          this.setFocus((index + 1).toString());
        } break;
      }
      case 38: {
        event.preventDefault();
        if (index === 0) {
          this.setFocus(this.searchAttributeValue);
        } else {
          this.setFocus((index - 1).toString());
        }
      }
    }
  }

  private setSearchValue(value: string): void {
    this.search.setValue(value);
  }

  private setFocus(attribueValue: string): void {
    const element: HTMLElement | null = this.elementRef.nativeElement.querySelector(`[data-autocomplete-element='${attribueValue}']`);
    if (element) {
      element.focus();
    }
  }

  private filterItems(search: string): void {
    this.filteredItems = this.items.filter(item =>
      item.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }

}
