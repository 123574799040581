import { Unit } from '../models/unit';

export const Unit1 = {
  name: '1',
  id: 1,
  localTelecom: '1234567'
};

export const Unit2 = {
  name: '2',
  id: 2,
  localTelecom: undefined
};

export const Unit3 = {
  name: '3',
  id: 3,
  localTelecom: ''
};

export const Unit4 = {
  name: '4',
  id: 4,
  localTelecom: ''
};

export const Unit5 = {
  name: '5',
  id: 5,
  localTelecom: ''
};

export const Unit6 = {
  name: '6',
  id: 6,
  localTelecom: ''
};

export const units: Unit[] = [
  Unit1,
  Unit2,
  Unit3,
  Unit4,
  Unit5,
  Unit6
];
