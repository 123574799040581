import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Lock, LockDeviceType } from '../../models/lock';

@Pipe({
  name: 'lockBattery'
})
export class LockBatteryPipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) {}

  transform(lock: Lock): string {
    if (this.isWiredConnection(lock)) {
      return 'Connected';
    } else if (lock.currentBattery) {
      return `${lock.currentBattery.percentage}% (Updated: ${this.datePipe.transform(lock.currentBattery.date, 'MM/dd/YY') || ''})`;
    }
    return 'N/A';
  }

  private isWiredConnection(lock: Lock): boolean {
    return lock.deviceType === LockDeviceType.R || lock.deviceType === LockDeviceType.R2;
  }

}
