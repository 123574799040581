import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LatchAuthGuardService, LatchNonAuthGuardService } from '@latch/latch-web';
import { LoadAccountGuard } from './guards/load-account/load-account.guard';
import { AccountComponent } from './pages/account/account.component';
import { ActiveSessionsPageComponent } from './pages/active-sessions-page/active-sessions-page.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { ConsoleComponent } from './pages/console/console.component';
import { DoorsComponent } from './pages/doors/doors.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ManagersComponent } from './pages/managers/managers.component';
import { ManangerDetailsComponent } from './pages/mananger-details/mananger-details.component';
import { RecentLoginsPageComponent } from './pages/recent-logins-page/recent-logins-page.component';
import { SignOffConfirmationComponent } from './pages/sign-off-confirmation/sign-off-confirmation.component';

const routes: Routes = [
  { path: '', redirectTo: 'console/activities', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LatchNonAuthGuardService] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LatchNonAuthGuardService] },
  {
    path: 'console',
    component: ConsoleComponent,
    canActivate: [LatchAuthGuardService, LoadAccountGuard],
    children: [
      { path: '', redirectTo: 'activities', pathMatch: 'full' },
      { path: 'activities', component: ActivitiesComponent },
      { path: 'account', component: AccountComponent },
      { path: 'active-sessions', component: ActiveSessionsPageComponent },
      { path: 'recent-logins', component: RecentLoginsPageComponent },
      { path: 'managers', component: ManagersComponent },
      { path: 'manager-details/:personUUID', component: ManangerDetailsComponent },
      { path: 'doors', component: DoorsComponent },
    ]
  },
  { path: 'sign-off-confirmation/:buildingName', component: SignOffConfirmationComponent },
  { path: '403', component: ErrorPageComponent },
  { path: '404', component: ErrorPageComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
