import { Pipe, PipeTransform } from '@angular/core';
import { ActiveUserSession } from '../../models/auth';
import { pluralize } from '../../utilities/pluralize';

@Pipe({
  name: 'activeSessions'
})
export class ActiveSessionsPipe implements PipeTransform {

  transform(activeSessions: ActiveUserSession[]): string {
    return activeSessions.length === 0 ? 'None' :
      `${activeSessions.length} Active ${pluralize(activeSessions.length, 'Session')}`;
  }

}
