import { Injectable } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { logError } from '@latch/latch-web';
import { AuthService } from '../../core/services/auth/auth.service';

/**
 * Provides the ability to show surveys to the user and present pop-ups to help
 * guide the user.
 *
 * This service is responsible for wiring up the necessary events - actual surveys
 * and flows are created in the Appcues platform. By default, this service registers
 * URL changes as page events. If clients want to trigger behavior based on other
 * events that don't cause a URL change, they should register them using this
 * service's track method (then use the Appcues platform to trigger a flow when
 * that event occurs.)
 *
 * The Appcues library should be loaded in the root html file with a script tag:
 * <script src="//fast.appcues.com/64875.js"></script>
 */
@Injectable({ providedIn: 'root' })
export class AppcuesService {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * initialize should be called once at app launch.
   *
   * It is responsible for registering app state changes when the user logs
   * in / out and when we navigate to a new page.
   */
  initialize() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Register any page change events with AppCues so that we can easily
        // trigger AppCues flows on any page. If parts of the app want to
        // trigger off a user action that does not involve a URL change, they
        // should manually trigger an AppCues event using track().
        // Guard with 'Appcues' check so that app is unaffected if Appcues fails
        // to load.
        if (windowHasAppcues(window)) {
          window.Appcues.page();
        }
      }
    });

    this.authService.user$
      .subscribe(user => {
        // Guard all these calls with window['Appcues'] so that if AppCues doesn't load
        // the application is unaffected.
        if (user && windowHasAppcues(window)) {
          // Register this user with Appcues so that we can appropriately choose when to survey
          // this user. For now, we will ONLY log their user id. We choose not to include any
          // other identifying information so that even if AppCues were to have a data breach,
          // we would not have any personally identifiable information stored there.
          window.Appcues.identify(user.uuid);
        } else if (!user && windowHasAppcues(window)) {
          // If there's no currently logged in user, de-register the user in Appcues so that we
          // don't target the last-logged-in user on the login pages.
          window.Appcues.reset();
        } else if (!windowHasAppcues(window)) {
          // If for some reason Appcues has failed to load, log the error.
          logError('Could not load Appcues');
        }
      });
  }

  public track(eventName: string, eventProperties: { [key: string]: string | number | boolean }): void {
    // Guard with window['Appcues'] so that if AppCues doesn't load the application is unaffected.
    // Note that we don't log an error on EVERY event; just when the logged in user changes (see
    // initialize).
    if (windowHasAppcues(window)) {
      window.Appcues.track(eventName, eventProperties);
    }
  }
}

function windowHasAppcues(window: Window): window is Window & {
  Appcues: any
} {
  return window && 'Appcues' in window;
}
