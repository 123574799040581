import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'latch-active-sessions-page',
  templateUrl: './active-sessions-page.component.html',
  styleUrls: ['./active-sessions-page.component.scss']
})
export class ActiveSessionsPageComponent {

  constructor(
    private router: Router
  ) { }

  public onCancelClick() {
    this.router.navigate(['/console/account'], { queryParamsHandling: 'preserve' });
  }

  public handleLogOutCompleted() {
    this.router.navigate(['/console/account'], { queryParamsHandling: 'preserve' });
  }

}
