import { Component, OnInit } from '@angular/core';
import { LatchVersionInfoService } from '@latch/latch-web';

@Component({
  selector: 'latch-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public versionNumber = '';

  ngOnInit(): void {
    this.versionNumber = LatchVersionInfoService.getHumanReadableVersion();
  }

}
