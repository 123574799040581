import { forwardRef, ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { MockAuthService } from './services/auth/mock-auth.service';
import {
  getPayload,
  LatchAuthModule,
  LatchAuthService,
  LATCH_APPLICATION_NAME,
  LATCH_LOG_ENDPOINT_BLACKLIST
} from '@latch/latch-web';
import { LatchUserAccountModule } from '@latch/latch-web/user-account';
import { AccountService } from './services/account/account.service';
import { MockAccountService } from './services/account/mock-account.service';
import { HttpAccountService } from './services/account/http-account.service';
import { BuildingService } from './services/building/building.service';
import { httpInterceptorProviders } from '../interceptors/http-interceptor';
import { environment } from '../../environments/environment';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityService } from './services/activity/activity.service';
import { MockActivityService } from './services/activity/mock-activity.service';
import { HttpActivityService } from './services/activity/http-activity.service';
import { LockService } from './services/lock/lock.service';
import { MockLockService } from './services/lock/mock-lock.service';
import { HttpLockService } from './services/lock/http-lock.service';
import { UnitService } from './services/unit/unit.service';
import { MockUnitService } from './services/unit/mock-unit.service';
import { HttpUnitService } from './services/unit/http-unit.service';
import { IntercomService } from './services/intercom/intercom.service';
import { MockIntercomService } from './services/intercom/mock-intercom.service';
import { HttpIntercomService } from './services/intercom/http-intercom.service';
import { ManagerService } from './services/manager/manager.service';
import { MockManagerService } from './services/manager/mock-manager.service';
import { HttpManagerService } from './services/manager/http-manager.service';

export function getErrorCode<T>(response: HttpErrorResponse): T {
  return getPayload(response) as T;
}

const DEFAULT_LOG_ENDPOINT_BLACKLIST = ['/web/v1/operations'];

@NgModule({
  declarations: [],
  imports: [
    LatchAuthModule.forRoot({
      loginApiUrl: `${environment.apiBaseUrl}/web/v1/auth/token`,
      logoutApiUrl: `${environment.apiBaseUrl}/web/v1/auth/token/revoke`,
      identityApiUrl: `${environment.apiBaseUrl}/web/v1/me`,
      refreshTokenApiUrl: `${environment.apiBaseUrl}/web/v1/auth/token/refresh`,
      forgotPasswordApiUrl: `${environment.apiBaseUrl}/web/v2/user-accounts/password/reset`,
      activeUserSessionsApiUrl: `${environment.apiBaseUrl}/web/v1/me/sessions`,
      revokeSessionsApiUrl: `${environment.apiBaseUrl}/web/v1/me/sessions`,
      loginPath: '/login',
      forgotPasswordPath: '/forgot-password',
      defaultPagePath: '/',
      loginScope: 'MANAGER_WEB',
      errorCodeGetter: getErrorCode,
      auth0AuthenticatorAudience: `${environment.auth0Config.audience}`,
      updateMFASettingsApiUrl: `${environment.apiBaseUrl}/web/v2/me/mfa`,
      enableMFAOrCancelEnrollmentApiUrl: `${environment.apiBaseUrl}/web/v2/me/mfa`,
      verifyMFACodeApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/verify`,
      validatePasswordResetTokenApiUrl: `${environment.apiBaseUrl}/web/v1/user-accounts/password/status`,
      sendPushNotificationApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/push`,
      checkPushNotificationStatusApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/push/:id/status`,
      requestMFACodeApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/codes`,
      requireMFAForPortfolioApiUrl: `${environment.apiBaseUrl}/web/v1/accounts/:id/mfa`,
      optoutFromMfaApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/optout`,
      useMocks: environment.useMocks,
      auth0Config: environment.auth0Config
    }),
    LatchUserAccountModule.forRoot({
      changePasswordApiUrl: `${environment.apiBaseUrl}/web/v1/me/password`,
      useMocks: environment.useMocks
    }),
  ],
  exports: [
    LatchAuthModule,
    LatchUserAccountModule,
  ],
  providers: [
    { provide: LATCH_LOG_ENDPOINT_BLACKLIST, useValue: DEFAULT_LOG_ENDPOINT_BLACKLIST },
    { provide: LATCH_APPLICATION_NAME, useValue: 'Manager Console' },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.reCaptchaToken } as RecaptchaSettings },
    AuthService,
    { provide: LatchAuthService, useExisting: forwardRef(() => AuthService) },
    httpInterceptorProviders,
    BuildingService,
  ]
})
export class CoreModule {
  static forRoot(useMocks: boolean): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: useMocks ? [
        { provide: AuthService, useClass: MockAuthService },
        { provide: AccountService, useClass: MockAccountService },
        { provide: ActivityService, useClass: MockActivityService },
        { provide: LockService, useClass: MockLockService },
        { provide: UnitService, useClass: MockUnitService },
        { provide: IntercomService, useClass: MockIntercomService },
        { provide: ManagerService, useClass: MockManagerService }
      ] : [
        { provide: AccountService, useClass: HttpAccountService },
        { provide: ActivityService, useClass: HttpActivityService },
        { provide: LockService, useClass: HttpLockService },
        { provide: UnitService, useClass: HttpUnitService },
        { provide: IntercomService, useClass: HttpIntercomService },
        { provide: ManagerService, useClass: HttpManagerService }
      ]
    };
  }
}
