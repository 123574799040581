import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LatchNavbarBuilding, LatchNavbarProfileMenu, LatchNavLink, User } from '@latch/latch-web';
import { combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { BuildingService } from '../../core/services/building/building.service';
import { Building, BuildingFeature } from '../../models/building';
import { ErrorStatus } from '../../models/errors';
import { FullNamePipe } from '../../pipes/full-name/full-name.pipe';
import { ConstantsService } from '../../services/constants/constants.service';
import { AppError, ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';
import { UIStateService } from '../../services/ui-state/ui-state.service';
import { naturalSortBy } from '../../utilities/natural-sort-by';
import { SelectedAccountService } from 'src/app/services/selected-account/selected-account.service';
import { Account } from 'src/app/models/account';
import { AccountService } from 'src/app/core/services/account/account.service';

@Component({
  selector: 'latch-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public hasActiveOperations = false;
  public isFocusMode$: Observable<boolean> = this.uiStateService.isFocusMode();
  public buildings: Building[] = [];
  public selectedBuilding!: Building;
  public selectedBuildingNote = '';
  public links: LatchNavLink[] = [
    {
      id: 'doors-link',
      name: 'Doors',
      path: '/console/doors'
    },
    {
      id: 'activities-link',
      name: 'Activity',
      path: '/console/activities'
    },
    {
      id: 'managers-link',
      name: 'View Managers',
      path: '/console/managers'
    }
  ];
  public profileMenu: LatchNavbarProfileMenu = {
    label: this.currentUser ? this.fullNamePipe.transform(this.currentUser) : '',
    profile: '/console/account',
    support: '',
    logout: (): void => this.logout(),
    menuItems: [],
    resources: '',
  };
  public selectedAccount?: Account;
  public accounts: Account[] = [];

  public megaMenuSelectedAccount?: Account;
  public megaMenuSelectedBuilding?: Building;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private uiStateService: UIStateService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private ngZone: NgZone,
    private constants: ConstantsService,
    private buildingService: BuildingService,
    private selectedBuildingsService: SelectedBuildingsService,
    private fullNamePipe: FullNamePipe,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private selectedAccountService: SelectedAccountService,
  ) { }

  get currentUser(): User | null {
    return this.authService.currentUser;
  }

  ngOnInit(): void {
    this.isLoading = true;
    combineLatest([
      this.accountService.getAccounts(),
      this.selectedAccountService.getSelectedAccount(),
    ]).pipe(
      switchMap(([accounts, selectedAccount]) => {
        this.accounts = accounts;
        this.selectedAccount = selectedAccount;
        this.megaMenuSelectedAccount = this.selectedAccount;
        return this.buildingService.getBuildings();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: buildings => {
        this.isLoading = false;
        this.buildings = this.filterAndSortBuildings(this.selectedAccount?.buildings);
        if (buildings.length === 0) {
          this.errorHandlerService.handleException(new AppError(
            ErrorStatus.Permission,
            AppError.UserHasNoBuildings
          ));
        }
      },
      error: error => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      }
    });

    this.selectedBuildingsService.getSelectedBuildings().pipe(
      filter((buildings) => buildings.length > 0),
      // UI currently only supports one selected building, so just pick the first one
      map((buildings) => buildings[0]),
      tap((building) => {
        this.selectedBuilding = building;
        this.megaMenuSelectedBuilding = building;
        this.selectedBuildingNote = '';
        this.selectedBuildingNote = this.selectedBuilding.features &&
          this.selectedBuilding.features.indexOf(BuildingFeature.Commercial) > -1 ? 'Commercial' : 'Residential';
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      error: () => {
        // Encountered an error in retrieving the list of buildings.
        // Empty error handler here to avoid allowing this error to be rethrown as a global error.
        // Note that the case where current UUID is invalid
        // will not reach here - instead, getSelectedBuilding will
        // return the first valid building as the selected building, but also redirect.
      }
    });
    // Auto-logout if nothing happens for some period of time (InactivityAutoLogoutTimeout). Use
    // runOutsideAngular to prevent change-detection from running on every event.
    this.ngZone.runOutsideAngular(() => {
      merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'touchstart')
      ).pipe(
        debounceTime(this.constants.InactivityAutoLogoutTimeout),
        takeUntil(this.unsubscribe$),
      ).subscribe(() => this.ngZone.run(() => this.logout()));
    });
  }

  selectedBuildingChange(building: Building) {
    this.selectedBuilding = building;
    const urlTree = this.router.createUrlTree([''], { queryParams: { building: building.uuid } });
    const url = this.router.serializeUrl(urlTree);

    if (this.megaMenuSelectedAccount && this.megaMenuSelectedAccount !== this.selectedAccount) {
      this.selectedAccountService.selectAccount(this.megaMenuSelectedAccount.uuid);
      location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
    this.selectedAccount = this.megaMenuSelectedAccount;
    this.selectedBuilding = building;
  }

  selectedAccountChange(account: Account) {
    this.megaMenuSelectedAccount = account;
    this.updateBuildings();
  }

  onMegaMenuOpen(isOpen: boolean): void {
    if (isOpen) {
      this.megaMenuSelectedAccount = this.selectedAccount;
      this.megaMenuSelectedBuilding = this.selectedBuilding;
      this.updateBuildings();
    }
  }

  filterAndSortBuildings(buildings?: Building[]): Building[] {
    return naturalSortBy(buildings?.filter(b => b.features?.some(f => f === BuildingFeature.DeliveryAssistant)) ?? [], 'name');
  }

  updateBuildings() {
    this.buildings = this.filterAndSortBuildings(this.megaMenuSelectedAccount?.buildings);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.errorHandlerService.clear();
    this.isLoading = true;
    this.authService.logout().subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['/login']);
    });
  }
}
