import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { UnitService } from '../../core/services/unit/unit.service';
import { NumberOfPackagesSelect, UnitPackageTypeSelect, unitPackageTypeSelect } from '../../models/activity';
import { Unit } from '../../models/unit';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';

const numberOfPackagesSelectStart = 1;

const numberOfPackagesSelectEnd = 100;
@Component({
  selector: 'latch-activity-units',
  templateUrl: './activity-units.component.html',
  styleUrls: ['./activity-units.component.scss']
})
export class ActivityUnitsComponent implements OnInit, OnDestroy {
  @Input() activityUnits!: UntypedFormArray;
  @Input() clearRows = false;
  @Input() numberOfUnitsPerAdd = 0;
  @Input() set isEditable(isEditable: boolean) {
    this.setIsEditableValue(isEditable);
  }
  public isEditableValue = false;
  public units: Unit[] = [];
  public unitNames: string[] = [];
  public unitPackageTypeSelect: UnitPackageTypeSelect[] = unitPackageTypeSelect;
  public activeAutocomplete: number | null = null;
  public numberOfPackagesSelect: NumberOfPackagesSelect[] = [];

  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private unitService: UnitService
  ) {
  }

  ngOnInit(): void {
    this.setNumberOfPackagesSelect();
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      map(buildings => buildings[0].uuid),
      switchMap(buildingUUID => this.unitService.getUnits(buildingUUID)),
      takeUntil(this.unsubscribe$)
    ).subscribe(units => {
      this.units = units;
      this.unitNames = this.units.map(unit => unit.name);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addActivityUnits(numberOfUnits: number): void {
    for (let i = 0; i < numberOfUnits; i++) {
      this.activityUnits.push(this.newActivityUnit());
    }
  }

  removeActivityUnit(index: number): void {
    this.activityUnits.removeAt(index);
  }

  setActivityUnit(unitName: string, index: number): void {
    const unit = this.units.find(u => u.name === unitName);
    if (unit) {
      this.activityUnits.at(index)?.get('id')?.setValue(unit.id);
      this.activityUnits.at(index)?.get('displayName')?.setValue(unit.name);
    }
    this.setActiveAutocomplete(null);
  }

  setActiveAutocomplete(value: number | null): void {
    this.activeAutocomplete = value;
  }

  private setNumberOfPackagesSelect(): void {
    let numberOfPackagesSelectValue = numberOfPackagesSelectStart;
    while (numberOfPackagesSelectValue <= numberOfPackagesSelectEnd) {
      this.numberOfPackagesSelect.push({
        name: numberOfPackagesSelectValue.toString(),
        value: numberOfPackagesSelectValue
      });
      numberOfPackagesSelectValue++;
    }
  }

  private setIsEditableValue(isEditable: boolean): void {
    this.isEditableValue = isEditable;
    this.checkActivityUnits();
  }

  private checkActivityUnits(): void {
    if (this.activityUnits) {
      const addEmptyUnitsCount = this.numberOfUnitsPerAdd - this.activityUnits.controls.length;
      if (addEmptyUnitsCount > 0) {
        this.addActivityUnits(addEmptyUnitsCount);
      }
      this.enableDisableActivityUnitsFields();
    }
  }

  private enableDisableActivityUnitsFields(): void {
    for (let i = 0; i < this.activityUnits.controls.length; i++) {
      if (this.isEditableValue) {
        this.activityUnits.at(i)?.get('numberOfPackages')?.enable();
        this.activityUnits.at(i)?.get('packageType')?.enable();
      } else {
        this.activityUnits.at(i)?.get('numberOfPackages')?.disable();
        this.activityUnits.at(i)?.get('packageType')?.disable();
      }
    }
  }

  private newActivityUnit(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(undefined),
      numberOfPackages: new UntypedFormControl(undefined),
      packageType: new UntypedFormControl(unitPackageTypeSelect[0].value),
      displayName: new UntypedFormControl('')
    });
  }

}
