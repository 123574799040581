import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ActivityInfo, ActivityList, ActivityUnit, UnitPackageType } from '../../models/activity';

const unitPackageTypeNames: Record<UnitPackageType, string> = {
  [UnitPackageType.LARGE_PACKAGE]: 'Large Package',
  [UnitPackageType.MEDICAL_ITEM]: 'Medical Item',
  [UnitPackageType.OTHER]: 'Other',
  [UnitPackageType.PARCEL]: 'Parcel',
  [UnitPackageType.PERISHABLE_ITEM]: 'Perishable Item'
};
@Pipe({
  name: 'activityInfo'
})
export class ActivityInfoPipe implements PipeTransform {

  transform(activity: Activity | ActivityList, info: ActivityInfo): string {
    switch (info) {
      case 'title': return `${activity.id} (${activity.courier.trim()})`;
      case 'packages': return this.getActivityPackages(activity.units);
    }
  }

  private getActivityPackages(activityUnits: ActivityUnit[]): string {
    const unitPackages: Record<UnitPackageType, number> = {
      [UnitPackageType.LARGE_PACKAGE]: 0,
      [UnitPackageType.MEDICAL_ITEM]: 0,
      [UnitPackageType.OTHER]: 0,
      [UnitPackageType.PARCEL]: 0,
      [UnitPackageType.PERISHABLE_ITEM]: 0
    };
    activityUnits.forEach(activityUnit => {
      unitPackages[activityUnit.packageType] += activityUnit.numberOfPackages;
    });
    const packages: string[] = [];
    Object.keys(unitPackages).forEach(key => {
      if (unitPackages[key as UnitPackageType] > 0) {
        packages.push(`${unitPackageTypeNames[key as UnitPackageType]} (${unitPackages[key as UnitPackageType].toString()})`);
      }
    });
    return packages.join(', ');
  }

}
