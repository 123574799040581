import * as _ from 'lodash';

/**
 * Sort an array by Natural Sort Order
 *
 * For example:
 * ['100A', '1A', '1B', '2A'] =>
 * ['1000', '100A', '1A', '1B', '2A'] (normal sort)
 * ['1A', '1B', '2A', '100A', '1000'] (natural sort)
 *
 * @param collection Array<T>
 * @param iteratee Parameter to sort by.
 */
 export function naturalSortBy<T, K extends keyof T, F extends (item: T) => string>(
    collection: T[],
    iteratee: K | F
  ): T[] {
    return collection.sort((el1, el2) => {
      let value1 = '';
      let value2 = '';
      if (_.isFunction(iteratee)) {
        value1 = iteratee(el1) || '';
        value2 = iteratee(el2) || '';
      } else {
        value1 = String(el1[iteratee]) || '';
        value2 = String(el2[iteratee]) || '';
      }
      return value1.localeCompare(value2, navigator.languages[0] ||
            navigator.language,
            { numeric: true }) > 0
          ? 1 : -1;
    });
  }
