import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivityUnit } from '../models/activity';

export function getFilledUnits(units: ActivityUnit[]): ActivityUnit[] {
    return units.filter((u: ActivityUnit) => !!u.id && !!u.numberOfPackages && !!u.packageType);
}

export function validateUnits(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => getFilledUnits(c.value).length > 0 ? null : { invalidUnits: true };
}
