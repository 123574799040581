import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { isDateTime, momentToDateTime } from '../../utilities/date-time';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'changesCount'
})
export class ChangesCountPipe implements PipeTransform {

  transform(baseData: any, checkData: any, checkKeys: string[]): number {
    let changes = 0;
    checkKeys.forEach(key => {
      let baseValue = baseData[key];
      let checkValue = checkData[key];
      if (isDateTime(baseValue) !== isDateTime(checkValue)) {
        if (!isDateTime(baseValue)) {
          baseValue = momentToDateTime(moment(baseValue));
        } else {
          checkValue = momentToDateTime(moment(checkValue));
        }
      }
      if (!_.isEqual(baseValue, checkValue)) {
        changes++;
      }
    });
    return changes;
  }

}
