import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Activity, ActivityListResponse, CreateActivityRequest, UpdateActivityRequest } from '../../../models/activity';
import { ActivitiesFilter } from '../../../models/filter';
import { AuthService } from '../auth/auth.service';
import { ActivityService } from './activity.service';
import { HttpResponse } from '@angular/common/http';
import { CourierNamesResponse } from '../../../models/courier';

@Injectable({
  providedIn: 'root'
})
export class HttpActivityService extends ActivityService {

  constructor(private authService: AuthService) {
    super();
  }

  getActivities(buildingUuid: string, filter: ActivitiesFilter): Observable<ActivityListResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-requests`,
      search: {
        page: filter.page.toString(),
        sort: filter.sort,
        q: filter.search
      }
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  createActivity(buildingUuid: string, activity: CreateActivityRequest): Observable<Activity> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-requests`,
      data: activity
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  getActivity(buildingUuid: string, activityId: string): Observable<Activity> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-requests/${activityId}`
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  deleteLog(buildingUuid: string, activityId: string): Observable<null> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-requests/${activityId}`
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  updateActivity(buildingUuid: string, activityId: string, input: UpdateActivityRequest): Observable<Activity> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-requests/${activityId}`,
      data: input
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  getCourierNames(): Observable<CourierNamesResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/courierNames'
    }).pipe(
      map((response: HttpResponse<any>): CourierNamesResponse => AuthService.getPayload(response))
    );
  }
}
