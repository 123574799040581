import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocksFilter } from '../../../models/filter';
import { Lock } from '../../../models/lock';
import { naturalSortBy } from '../../../utilities/natural-sort-by';

@Injectable({
  providedIn: 'root'
})
export abstract class LockService {

  getFilteredLocks(locks: Lock[], filter: LocksFilter): Lock[] {
    let filteredLocks = locks.filter(lock => (
      !filter.search ||
      (lock.name.toLowerCase().indexOf(filter.search.toLowerCase()) > -1)
    ));
    if (filter.sort.active) {
      filteredLocks = filter.sort.direction === 'asc' ?
        naturalSortBy(filteredLocks, filter.sort.active as keyof Lock).reverse() :
        naturalSortBy(filteredLocks, filter.sort.active as keyof Lock);
    }
    return filteredLocks;
  }

  abstract unlockLock(lockUUID: string): Observable<any>;

  abstract getBuildingLocksWithIntercom(buildingUuid: string): Observable<Lock[]>;

  abstract getBuildingLocks(buildingUuid: string): Observable<Lock[]>;
}
