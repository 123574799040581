import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntercomCommon } from '../../../models/intercom';

@Injectable({
  providedIn: 'root'
})
export abstract class IntercomService {

  abstract getBuildingIntercoms(buildingUuid: string): Observable<IntercomCommon[]>;
}
