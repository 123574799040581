<latch-loading [isActive]="isLoading">
    <div class="page-wrapper">
        <ng-container *ngIf="manager">
            <latch-subnav [headerTitle]="manager | fullName" [pageContext]="subnavPageContext">
            </latch-subnav>
            <div class="page-content top-border">
                <div class="latch-viewport">
                    <latch-list>
                        <latch-labeled-list-item class="half" label="First Name">
                            {{manager.firstName}}
                        </latch-labeled-list-item>
                        <latch-labeled-list-item class="half" label="Last Name">
                            {{manager.lastName}}
                        </latch-labeled-list-item>
                        <div class="latch-divider"></div>
                        <latch-labeled-list-item class="half" label="Phone number">
                            {{manager.phoneNumber}}
                        </latch-labeled-list-item>
                        <latch-labeled-list-item class="half" label="Email">
                            {{manager.email}}
                        </latch-labeled-list-item>
                    </latch-list>
                </div>
            </div>
        </ng-container>
    </div>
</latch-loading>