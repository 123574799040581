<latch-loading [isActive]="isLoading">
  <latch-card class="page fixed" #cardElement>
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="handleClose(false)">
          <span class="latch-icon-close"></span>
      </button>
      <a *ngIf="!isEditing" class="latch-button-link" (click)="enterEditMode()">Edit</a>
      <a *ngIf="isEditing" class="latch-button-link" (click)="cancelEditMode()">Cancel</a>
      </div>
      <div class="latch-card-title" *ngIf="activity">{{activity | activityInfo : 'title'}}</div>
    </ng-container>
    <ng-container latchCardContent>
      <latch-list [formGroup]="activityForm">
        <latch-labeled-list-item label="Delivery type">
            <div>
                <latch-dropdown [data]="deliverySelect" formControlName="deliveryType"></latch-dropdown>
            </div>
        </latch-labeled-list-item>
        <div class="full-width">
            <latch-tabs [selectedTab]="selectedTab" [tabs]="activityTabs" (tabClicked)="selectTab($event)"></latch-tabs>
        </div>
        <ng-container *ngIf="selectedTab === 'building'">
            <ng-container *ngIf="activity && activity?.accessLog?.image?.url">
                <latch-labeled-list-item label="Photo">
                    <div class="activity-image-container">
                        <div class="activity-image"
                            [class.rotate]="activity.accessLog?.image?.imageSource === ImageSource.LOCK">
                            <img [src]="activity.accessLog?.image?.url">
                        </div>
                    </div>
                </latch-labeled-list-item>
                <div class="latch-divider"></div>
            </ng-container>
            <latch-labeled-list-item class="half" label="Name">
                <input class="form-control" formControlName="buildingName" type="text">
            </latch-labeled-list-item>
            <latch-labeled-list-item class="half" label="Organization">
                <input class="form-control" formControlName="organization" type="text">
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item label="Door">
                <div>
                    <latch-dropdown *ngIf="locksSelect" [data]="locksSelect" formControlName="lock"></latch-dropdown>
                </div>
            </latch-labeled-list-item>
        </ng-container>
        <ng-container *ngIf="selectedTab === 'delivery'">
            <latch-labeled-list-item label="Comment">
                <input class="form-control" formControlName="comment" placeholder="Comment">
                <div class="has-error help-block"
                    *ngIf="isEditing && (activityForm.get('comment')?.errors?.minlength || activityForm.get('comment')?.errors?.maxlength)">
                    Comment length must be between {{commentMinLength}} and {{commentMaxLength}}
                </div>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item label="Courier">
                <div class="field-value" *ngIf="!isEditing">{{activityForm.get('courier')?.value}}</div>
                <ng-container *ngIf="isEditing">
                    <latch-autocomplete
                        placeholder="UPS"
                        [items]="couriers"
                        [autofocus]="false"
                        [initialValue]="activityForm.get('courier')?.value"
                        (valueChanged)="setCourierValue($event)"></latch-autocomplete>
                    <div class="has-error help-block" *ngIf="activityForm.get('courier')?.errors?.required">
                        Courier field cannot be empty
                    </div>
                    <div class="has-error help-block" *ngIf="activityForm.get('courier')?.errors?.minlength || activityForm.get('courier')?.errors?.maxlength">
                        Courier length must be between {{courierMinLength}} and {{courierMaxLength}}
                    </div>
                </ng-container>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item>
                <latch-activity-units *ngIf="activity" [activityUnits]="activityUnits" [isEditable]="isEditing" [clearRows]="true"></latch-activity-units>
                <div class="has-error help-block" *ngIf="isEditing && activityForm.get('units')?.errors?.invalidUnits">
                    Apartment unit and number of parcels is required to save the log.
                </div>
            </latch-labeled-list-item>
        </ng-container>
        <ng-container *ngIf="selectedTab === 'log'">
            <latch-labeled-list-item class="half" label="Status">
                <div>
                    <latch-dropdown [data]="statusSelect" formControlName="status"></latch-dropdown>
                </div>
            </latch-labeled-list-item>
            <latch-labeled-list-item class="half" label="Priority">
                <div>
                    <latch-dropdown [data]="prioritySelect" formControlName="priority"></latch-dropdown>
                </div>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item label="Revised Delivery Date">
                <latch-date-time-input [disableDate]="!isEditing" [disableTime]="!isEditing" formControlName="deliveryDateTime"></latch-date-time-input>
                <div class="has-error help-block" *ngIf="isEditing && activityForm.get('deliveryDateTime')?.errors?.invalidDateTime">
                    Invalid Date
                </div>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item label="Operator">
                <input class="form-control" formControlName="operator" type="text">
            </latch-labeled-list-item>
        </ng-container>
        <div class="latch-divider full-width"></div>
        <latch-labeled-list-item label="Delete Log">
            <div class="delete-button-container">
                <button class="latch-button latch-danger" (click)="deleteLog()">Delete Log</button>
            </div>
        </latch-labeled-list-item>
      </latch-list>
    </ng-container>
    <ng-container latchCardFooter>
      <div class="modal-buttons">
        <button *ngIf="!isEditing" class="latch-button latch-dark" (click)="handleClose(false)">Close</button>
        <button *ngIf="isEditing" class="latch-button latch-blue" [disabled]="!activityForm.valid" (click)="saveLog()">
            Save Changes ({{activityForm.value | changesCount : activity : checkKeys}})
        </button>
      </div>
    </ng-container>
  </latch-card>
</latch-loading>

