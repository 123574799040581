<div class="confirmation-container">
  <div class="confirmation-content">
    <div class="logo">
      <img src="/assets/images/latch-logo-white.svg">
    </div>
    <div class="title">Thank you.</div>
    <div class="message">
      Your package pickup has been confirmed. Thank you for helping us keep the package room organized.
      <ng-container *ngIf="buildingName">
        <br><br>
        Latch @ <span data-test-id="buildingName">{{buildingName}}</span>
      </ng-container>
    </div>
  </div>
</div>
