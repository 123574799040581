import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity, ActivityListResponse, CreateActivityRequest, UpdateActivityRequest } from '../../../models/activity';
import { CourierNamesResponse } from '../../../models/courier';
import { ActivitiesFilter } from '../../../models/filter';

@Injectable({
  providedIn: 'root'
})
export abstract class ActivityService {
  abstract getActivities(buildingUuid: string, filter: ActivitiesFilter): Observable<ActivityListResponse>;

  abstract createActivity(buildingUuid: string, activity: CreateActivityRequest): Observable<Activity>;

  abstract getActivity(buildingUuid: string, activityId: string): Observable<Activity>;

  abstract deleteLog(buildingUuid: string, activityId: string): Observable<null>;

  abstract updateActivity(buildingUuid: string, activityId: string, input: UpdateActivityRequest): Observable<Activity>;

  abstract getCourierNames(): Observable<CourierNamesResponse>;
}
