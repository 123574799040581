<div class="autocomplete-container">
    <input class="form-control"
        [placeholder]="placeholder"
        [formControl]="search"
        [attr.data-autocomplete-element]="searchAttributeValue"
        (keydown)="handleSearchKeyDown($event)">
    <div class="autocomplete-elements" *ngIf="!search.disabled && filteredItems.length > 0 && search.value">
        <div class="autocomplete-element"
            tabindex="0"
            *ngFor="let filteredItem of filteredItems; let i = index"
            (click)="selectItem(filteredItem)"
            (keydown)="handleElementKeyDown($event, i)"
            [attr.data-autocomplete-element]="i">
            {{filteredItem}}
        </div>
    </div>
</div>
