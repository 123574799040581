import { Building } from './building';

export interface Account {
  name: string;
  uuid: string;
  permissions: AccountPermissions[];
  mfa?: {
    smsRequired: boolean
  };
  buildings?: Building[];
}

export enum AccountPermissions {
  ViewLocks = 'VIEW_LOCKS',
  ViewBuildings = 'VIEW_BUILDINGS',
  ManagementConsole = 'MANAGEMENT_CONSOLE',
  ManageBuildings = 'MANAGE_BUILDINGS',
  CreateAdministrator = 'CREATE_ADMINISTRATOR',
  ViewDeliveryLogs = 'VIEW_DELIVERY_LOGS'
}

export type PasswordStep = 'initiateChangePassword' | 'confirmChangePassword' | 'confirmEmailSent';
