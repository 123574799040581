import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { transformIdsToLowercase } from '../../utilities/transfrom-ids-to-lowercase';

@Injectable()
export class UUIDInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const payload = AuthService.getPayload(event);
          if (payload) {
            event.body.payload.message = transformIdsToLowercase(payload);
          }
        } else if (event instanceof HttpErrorResponse) {
          const payload = AuthService.getPayload(event);
          if (payload) {
            event.error.payload.message = transformIdsToLowercase(payload);
          }
        }
      }),
    );
  }
}
