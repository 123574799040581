import { Lock, LockAssignmentStatus, LockDeviceType, LockType, SyncState } from '../models/lock';
import * as moment from 'moment';

export const lockClermont1: Lock = {
  lockUUID: 'lock-clermont-1',
  buildingUUID: 'BUILDING-150-CLAREMONT-UUID',
  name: 'Clermont 1',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-clermont-1-sync',
    lockUUID: 'lock-clermont-1',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 80,
    voltage: 9.3,
    date: moment().subtract(3, 'days').unix()
  }
};

export const lockClermont2: Lock = {
  lockUUID: 'lock-clermont-2',
  buildingUUID: 'BUILDING-150-CLAREMONT-UUID',
  name: 'Clermont 2',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.R,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-clermont-2-sync',
    lockUUID: 'lock-clermont-2',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  }
};

export const lock220W1: Lock = {
  lockUUID: 'lock-220W-1',
  buildingUUID: 'BUILDING-220-W-107TH-UUID',
  name: '220W 1',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-220W-1-sync',
    lockUUID: 'lock-220W-1',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 95,
    voltage: 9.3,
    date: moment().subtract(3, 'days').unix()
  }
};

export const lock220W2: Lock = {
  lockUUID: 'lock-220W-2',
  buildingUUID: 'BUILDING-220-W-107TH-UUID',
  name: '220W 2',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-220W-2-sync',
    lockUUID: 'llock-220W-2',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 80,
    voltage: 9.3,
    date: moment().subtract(3, 'days').unix(),
  }
};

export const lockInfinite1: Lock = {
  lockUUID: 'lock-infinite-1',
  buildingUUID: 'BUILDING-9-INFINITE-LOOP-UUID',
  name: 'Infinite 1',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-infinite-1-sync',
    lockUUID: 'lock-infinite-1',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 65,
    voltage: 8.3,
    date: moment().subtract(3, 'days').unix(),
  }
};

export const lockInfinite2: Lock = {
  lockUUID: 'lock-infinite-2',
  buildingUUID: 'BUILDING-9-INFINITE-LOOP-UUID',
  name: 'Infinite 2',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-infinite-2-sync',
    lockUUID: 'lock-infinite-2',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 68,
    voltage: 9.3,
    date: moment().subtract(1, 'days').unix(),
  }
};

export const lockInfinite3: Lock = {
  lockUUID: 'lock-infinite-3',
  buildingUUID: 'BUILDING-9-INFINITE-LOOP-UUID',
  name: 'Rooftop Patio',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.R2,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-infinite-3-sync',
    lockUUID: 'lock-infinite-3',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 88,
    voltage: 9.3,
    date: moment().subtract(1, 'days').unix(),
  }
};

export const lockRockefeller1: Lock = {
  lockUUID: 'lock-Rockefeller-1',
  buildingUUID: 'ROCKEFELLER-CENTER-UUID',
  name: 'Rockefeller 1',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-Rockefeller-1-sync',
    lockUUID: 'lock-Rockefeller-1',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  }
};

export const lockRockefeller2: Lock = {
  lockUUID: 'lock-Rockefeller-2',
  buildingUUID: 'ROCKEFELLER-CENTER-UUID',
  name: 'Rockefeller 2',
  type: LockType.DOOR,
  lockState: LockAssignmentStatus.ASSIGNED,
  deviceType: LockDeviceType.C,
  earliestDeadlineSyncTaskStatusViews: {
    uuid: 'lock-clermont-1-sync',
    lockUUID: 'lock-clermont-1',
    deadline: moment().add(3, 'days').unix(),
    earliestDownlink: moment().add(1, 'days').unix(),
    state: SyncState.SyncScheduled
  },
  currentBattery: {
    percentage: 75,
    voltage: 9.3,
    date: moment().subtract(3, 'days').unix(),
  }
};

export const locks: Lock[] = [
  lockClermont1,
  lockClermont2,
  lock220W1,
  lock220W2,
  lockInfinite1,
  lockInfinite2,
  lockInfinite3,
  lockRockefeller1,
  lockRockefeller2
];
