import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { activities } from '../../../mock-db/activities';
import { locks } from '../../../mock-db/locks';
import { Activity, ActivityListResponse, CreateActivityRequest, UpdateActivityRequest } from '../../../models/activity';
import { mockResponse } from '../../../utilities/mock-response';
import { paginateList } from '../../../utilities/pagination';
import { ActivityService } from './activity.service';
import { ActivitiesFilter } from '../../../models/filter';
import { AuthService } from '../auth/auth.service';
import { mockUsers } from '../../../mock-db/users';
import { naturalSortBy } from '../../../utilities/natural-sort-by';
import { getFullName } from '../../../utilities/presentation';
import { CourierNamesResponse } from '../../../models/courier';
import { couriers } from '../../../mock-db/couriers';

const defaultPageSize = 20;
const ActivitiesSortingKeysMapping: Record<string, keyof Activity> = {
  lock: 'lock',
  lastUpdatedOn: 'lastUpdatedOn'
};

@Injectable({
  providedIn: 'root'
})
export class MockActivityService extends ActivityService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getActivities(buildingUuid: string, filter: ActivitiesFilter): Observable<ActivityListResponse> {
    return mockResponse(() => {
      const buildingLocks = locks.filter(l => l.buildingUUID === buildingUuid);
      let buildingActivities = activities.filter(
        a => buildingLocks.findIndex(bl => bl.lockUUID === a.lock.id) > -1
      );
      const sortElements = filter.sort.split(':');
      let sortKey: keyof Activity | '' = '';
      let sortDirection = '';
      if (sortElements.length === 2) {
        sortKey = ActivitiesSortingKeysMapping[sortElements[0]] || '';
        sortDirection = sortElements[1];
      }
      if (sortKey) {
        buildingActivities = sortDirection === 'ASC' ?
          naturalSortBy(buildingActivities, sortKey).reverse() :
          naturalSortBy(buildingActivities, sortKey);
      }
      const paginatedData = paginateList(this.filterActivities(buildingActivities, filter), filter.page, defaultPageSize);
      return {
        metadata: {
          page: paginatedData.page,
          pageSize: paginatedData.pageSize,
          totalPages: paginatedData.totalPages,
          totalElements: paginatedData.totalElements
        },
        elements: paginatedData.items.map(a => {
          const lock = buildingLocks.find(bl => bl.lockUUID === a.lock.id);
          return {
            id: a.id,
            lock: {
              id: lock && lock.lockUUID || '',
              displayName: lock && lock.name || ''
            },
            status: a.status,
            lastUpdatedOn: a.lastUpdatedOn,
            units: a.units,
            courier: a.courier,
            priority: a.priority,
            accessLog: a.accessLog
          };
        })
      };
    });
  }

  createActivity(buildingUuid: string, activity: CreateActivityRequest): Observable<Activity> {
    return mockResponse(() => {
      const todayTime = new Date().getTime();
      const lock = locks.find(l => l.lockUUID === activity.lockUUID);
      if (!lock) {
        throw new Error('Lock not found');
      }
      const user = this.authService.currentUser;
      if (!user) {
        throw new Error('User not found');
      }
      const activityId = _.uniqueId('activity-log-uuid');
      const activityData: Activity = {
        id: activityId,
        deliveryType: activity.deliveryType,
        lock: {
          id: lock.lockUUID,
          displayName: lock.name
        },
        courier: activity.courier,
        deliveryDateTime: activity.deliveryDateTime,
        comment: activity.comment || '',
        status: activity.status,
        priority: activity.priority,
        createdOn: todayTime,
        lastUpdatedOn: todayTime,
        createdBy: {
          id: user.uuid,
          displayName: getFullName(user.firstName ?? '', user.lastName)
        },
        lastUpdatedBy: {
          id: user.uuid,
          displayName: getFullName(user.firstName ?? '', user.lastName)
        },
        units: activity.units
      };
      activities.push(activityData);
      return activityData;
    });
  }

  getActivity(buildingUuid: string, activityId: string): Observable<Activity> {
    return mockResponse(() => {
      const activity = activities.find(a => a.id === activityId);
      if (!activity) {
        throw new Error('Activity not found');
      }
      const lock = locks.find(l => l.lockUUID === activity.lock.id);
      if (!lock) {
        throw new Error('Lock not found');
      }
      const createdByUser = mockUsers.find(u => u.uuid === activity.createdBy.id);
      if (!createdByUser) {
        throw new Error('Created by user not found');
      }
      const lastUpdatedByUser = mockUsers.find(u => u.uuid === activity.lastUpdatedBy.id);
      if (!lastUpdatedByUser) {
        throw new Error('Last updated by user not found');
      }
      return activity;
    });
  }

  deleteLog(buildingUuid: string, activityId: string): Observable<null> {
    return mockResponse(() => {
      const activityIndex = activities.findIndex(a => a.id === activityId);
      if (!activityIndex) {
        throw new Error('Activity not found');
      }
      activities.splice(activityIndex, 1);
      return null;
    });
  }

  updateActivity(buildingUuid: string, activityId: string, input: UpdateActivityRequest): Observable<Activity> {
    return mockResponse(() => {
      const activity = activities.find(a => a.id === activityId);
      if (!activity) {
        throw new Error('Activity not found');
      }
      const lock = locks.find(l => l.lockUUID === activity.lock.id);
      if (!lock) {
        throw new Error('Lock not found');
      }
      const createdByUser = mockUsers.find(u => u.uuid === activity.createdBy.id);
      if (!createdByUser) {
        throw new Error('Created by user not found');
      }
      const lastUpdatedByUser = mockUsers.find(u => u.uuid === activity.lastUpdatedBy.id);
      if (!lastUpdatedByUser) {
        throw new Error('Last updated by user not found');
      }
      activity.lock = {
        id: lock.lockUUID,
        displayName: lock.name
      };
      activity.status = input.status;
      activity.priority = input.priority;
      activity.deliveryType = input.deliveryType;
      activity.deliveryDateTime = input.deliveryDateTime;
      activity.courier = input.courier;
      activity.comment = input.comment || '';
      activity.lastUpdatedBy = {
        id: lastUpdatedByUser.uuid,
        displayName: getFullName(lastUpdatedByUser.firstName ?? '', lastUpdatedByUser.lastName)
      };
      activity.units = input.units;
      return activity;
    });
  }

  getCourierNames(): Observable<CourierNamesResponse> {
    return mockResponse(() => ({
      courierNames: couriers.map(courier => courier.name)
    }));
  }

  private filterActivities(activitiesData: Activity[], filter: ActivitiesFilter): Activity[] {
    return activitiesData.filter(a => {
      const createdByUser = mockUsers.find(u => u.uuid === a.createdBy.id);
      const createdByUserFullName = createdByUser ? `${createdByUser.firstName || ''} ${createdByUser.lastName || ''}` : '';
      return !filter.search ||
        (
          a.id.toLowerCase().indexOf(filter.search.toLowerCase()) > -1 ||
          a.courier.toLowerCase().indexOf(filter.search.toLowerCase()) > -1 ||
          (createdByUserFullName && createdByUserFullName.toLowerCase().indexOf(filter.search.toLowerCase()) > -1)
        );
    });
  }
}
