import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LatchNavAction } from '@latch/latch-web';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ManagerService } from '../../core/services/manager/manager.service';
import { PropertyManager } from '../../models/property-manager';
import { SelectedBuildingsService } from '../../services/selected-buildings/selected-buildings.service';

@Component({
  selector: 'latch-mananger-details',
  templateUrl: './mananger-details.component.html',
  styleUrls: ['./mananger-details.component.scss']
})
export class ManangerDetailsComponent implements OnInit, OnDestroy {
  public manager!: PropertyManager;
  public isLoading = false;
  public subnavPageContext: LatchNavAction = {
    id: 'managers-page-id',
    name: 'Managers',
    path: '/console/managers'
  };

  private unsubscribe$ = new Subject<void>();
  constructor(
    private activeRoute: ActivatedRoute,
    private managerService: ManagerService,
    private selectedBuildingsService: SelectedBuildingsService
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.pipe(
      filter(params => !!params.personUUID),
      takeUntil(this.unsubscribe$)
    ).subscribe(params => this.getManager(params.personUUID));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getManager(personUUID: string): void {
    this.isLoading = true;
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      map(buildings => buildings[0].uuid),
      switchMap(buildingUUID => this.managerService.getPropertyManagerByPersonUUID(buildingUUID, personUUID)),
      takeUntil(this.unsubscribe$)
    ).subscribe(manager => {
      this.manager = manager;
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

}
