import { UnlockStatus } from './lock';
import { Pagination } from './pagination';

export enum DeliveryType {
  PACKAGE = 'PACKAGE',
  OTHER = 'OTHER'
}

export enum ActivityStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD'
}

export enum ActivityPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export enum UnlockMethod {
  Passcode = 'PASSCODE',
  BLE = 'BLE',
  NFC = 'NFC',
  MKO = 'MKO'
}


export enum UnitPackageType {
  PARCEL = 'PARCEL',
  PERISHABLE_ITEM = 'PERISHABLE_ITEM',
  MEDICAL_ITEM = 'MEDICAL_ITEM',
  LARGE_PACKAGE = 'LARGE_PACKAGE',
  OTHER = 'OTHER'
}

export interface ActivityUnit {
  id: number;
  packageType: UnitPackageType;
  numberOfPackages: number;
  displayName: string;
}

export enum ImageSource {
  LOCK = 'LOCK',
  CAMERA = 'CAMERA'
}

export interface AccessLogImage {
  url?: string;
  imageSource?: ImageSource;
  imageFileName?: string;
  imageToken?: string;
}

export interface AccessLog {
    uuid: string;
    method: UnlockMethod;
    image?: AccessLogImage;
}

export interface Activity {
  id: string;
  lock: {
    id: string;
    displayName: string;
  };
  status: ActivityStatus;
  priority: ActivityPriority;
  deliveryType: DeliveryType;
  deliveryDateTime: number;
  courier: string;
  comment: string;
  createdOn: number;
  lastUpdatedOn: number;
  createdBy: {
    id: string;
    displayName: string;
  };
  lastUpdatedBy: {
    id: string;
    displayName: string;
  };
  units: ActivityUnit[];
  accessLog?: AccessLog;
  // This is temporary field since the api response is returning type instead of deliveryType
  // When the api is updated to return the proper key this will be removed
  type?: DeliveryType
}

export interface ActivityList {
  id: string;
  lock: {
    id: string;
    displayName: string;
  };
  status: ActivityStatus;
  lastUpdatedOn: number;
  units: ActivityUnit[];
  courier: string;
  priority: ActivityPriority;
  accessLog?: AccessLog;
}

export interface ActivityListResponse {
  metadata: Pagination;
  elements: ActivityList[];
}

export interface CreateActivityRequest {
  deliveryType: DeliveryType;
  lockUUID: string;
  courier: string;
  deliveryDateTime: number;
  status: ActivityStatus;
  priority: ActivityPriority;
  comment: string | null;
  units: ActivityUnit[];
  // This is a temporary field since the api needs this key in order to properly create the activity.
  // When the api is updated to work with out this key this will be removed.
  title: string;
}

export interface DeliverSelect {
  value: DeliveryType;
  name: string;
}

export interface StatusSelect {
  value: ActivityStatus;
  name: string;
}

export interface PrioritySelect {
  value: ActivityPriority;
  name: string;
}

export interface UnitPackageTypeSelect {
  value: UnitPackageType;
  name: string;
}

export interface NumberOfPackagesSelect {
    name: string;
    value: number;
}

export const deliverySelect: DeliverSelect[] = [
  { value: DeliveryType.PACKAGE, name: 'Package' },
  { value: DeliveryType.OTHER, name: 'Other' }
];

export const statusSelect: StatusSelect[] = [
  { value: ActivityStatus.OPEN, name: 'Open' },
  { value: ActivityStatus.PENDING, name: 'Pending' },
  { value: ActivityStatus.ON_HOLD, name: 'On hold' },
  { value: ActivityStatus.CLOSED, name: 'Closed' }
];

export const prioritySelect: PrioritySelect[] = [
  { value: ActivityPriority.LOW, name: 'Low' },
  { value: ActivityPriority.MEDIUM, name: 'Medium' },
  { value: ActivityPriority.HIGH, name: 'High' }
];

export const unitPackageTypeSelect: UnitPackageTypeSelect[] = [
  { value: UnitPackageType.PARCEL, name: 'Parcel' },
  { value: UnitPackageType.PERISHABLE_ITEM, name: 'Perishable item' },
  { value: UnitPackageType.MEDICAL_ITEM, name: 'Medical item' },
  { value: UnitPackageType.LARGE_PACKAGE, name: 'Large package' },
  { value: UnitPackageType.OTHER, name: 'Other' }
];

export interface UpdateActivityRequest {
  status: ActivityStatus;
  priority: ActivityPriority;
  deliveryType: DeliveryType;
  deliveryDateTime: number;
  courier: string;
  comment: string | null;
  units: ActivityUnit[];
  // This is a temporary field since the api needs this key in order to properly update the activity.
  // When the api is updated to work with out this key since is not editable this will be removed.
  lockUUID: string;
  // This is a temporary field since the api needs this key in order to properly update the activity.
  // When the api is updated to work with out this key this will be removed.
  title: string;
}

export type ActivityInfo = 'title' | 'packages';

export const commentMinLength = 3;
export const commentMaxLength = 2000;

export const courierMinLength = 2;
export const courierMaxLength = 128;

export interface CloseAddActivityModal {
  activityCreated: boolean;
  unlockStatus?: UnlockStatus
}
