import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Lock } from '../../../models/lock';
import { AuthService } from '../auth/auth.service';
import { BuildingService } from '../building/building.service';
import { IntercomService } from '../intercom/intercom.service';
import { LockService } from './lock.service';

@Injectable({
  providedIn: 'root'
})
export class HttpLockService extends LockService {

  constructor(
    private authService: AuthService,
    private buildingService: BuildingService,
    private intercomService: IntercomService
  ) {
    super();
  }

  getBuildingLocksWithIntercom(buildingUuid: string): Observable<Lock[]> {
    return this.intercomService.getBuildingIntercoms(buildingUuid).pipe(
      switchMap(buildingIntercoms => this.getBuildingLocks(buildingUuid).pipe(
        map(locks => locks.filter(l => buildingIntercoms.findIndex(i => i.lockUUID === l.lockUUID) > -1)),
        map(locks => locks.map(l => ({ ...l, intercomType: buildingIntercoms.find(i => i.lockUUID === l.lockUUID)?.type ?? '' })))
      ))
    );
  }

  getBuildingLocks(buildingUuid: string): Observable<Lock[]> {
    return this.buildingService.getAccountUUIDByBuildingUUID(buildingUuid).pipe(
      switchMap(accountUuid => this.authService.request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUuid}/buildings/${buildingUuid}/doors`
      })),
      map(response => AuthService.getPayload(response).map((r: any) => ({ ...r, buildingUUID: buildingUuid })))
    );
  }

  unlockLock(lockUUID: string): Observable<any> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/locks/${lockUUID}/unlock`,
      data: {}
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

}
