import * as _ from 'lodash';

/**
 * Determine whether value should be described as singular or plural. `value` may be an array of items
 * (to check the length of) or just an explicit number representing the count.
 */
 export const isPlural = (value: number | any[]) => {
    const count = _.isArray(value) ? value.length : value;
    return count !== 1;
  };

  /**
   * Make some label plural (by adding -s or -es) based on count of items to describe. `value` may be an array of items
   * (to check the length of) or just an explicit number representing the count.
   */
  export const pluralize = (value: number | any[], label: string): string =>
    isPlural(value) ? _.endsWith(label, 's') ? `${label}es` : `${label}s` : label;
