export interface Tab {
  id: string;
  name: string;
}

export const activityTabs: Tab[] = [
  {
    id: 'building',
    name: 'Building'
  },
  {
    id: 'delivery',
    name: 'Delivery'
  },
  {
    id: 'log',
    name: 'Log'
  }
];
