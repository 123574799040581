import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  getPayload,
  hasValidPayload,
  LatchAuthConfig,
  LatchAuthHTTPService,
  LatchHttpErrors,
  LATCH_AUTH_CONFIG,
  LATCH_HTTP_ERRORS,
  User
} from '@latch/latch-web';
import { Observable } from 'rxjs';
import { RequestParams } from '../../../models/auth';
import { APIService } from '../../../services/api/api.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';

@Injectable()
export class AuthService extends LatchAuthHTTPService {
  public isLoggedIn = false;
  public currentUser: User | null = null;

  static getPayload(response: HttpResponse<any> | HttpErrorResponse): any {
    return getPayload(response);
  }

  static hasValidPayload(response: HttpResponse<any> | HttpErrorResponse): boolean {
    return hasValidPayload(response);
  }

  public get session$(): Observable<boolean> {
    return this.isLoggedIn$;
  }

  constructor(
    private api: APIService,
    http: HttpClient,
    router: Router,
    @Inject(LATCH_AUTH_CONFIG) config: LatchAuthConfig,
    @Inject(LATCH_HTTP_ERRORS) httpErrors: LatchHttpErrors,
    auth0Service: Auth0Service
  ) {
    super(http, router, config, httpErrors, auth0Service);
  }

  initialize(): void {
    this.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.user$.subscribe(user => {
      this.currentUser = user;
    });
  }

  request(params: RequestParams): Observable<HttpResponse<any>> {
    const { method, endpoint, data, search, headers } = params;
    return this.api.request(method, endpoint, data, headers, search);
  }

  /**
   * Asks API to reset user's password and email them a new one.
   * TODO: Remove this function when the LW's forgotPassword will support captcha
   */
  forgotPasswordOld(email: string, captcha: string, clearBrowserHistory: boolean):
    Observable<HttpResponse<any>> {
    const data = {
      email,
      captcha,
      clearBrowserHistory
    };
    return this.api.request('post', '/web/v2/user-accounts/password/reset', data);
  }
}

