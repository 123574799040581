<latch-loading [isActive]="isLoading">
    <latch-subnav headerTitle="My Profile"></latch-subnav>
    <div class="page-content top-border top-spacing">
        <div class="latch-viewport">
            <latch-list *ngIf="user">
                <latch-labeled-list-item class="half" label="First Name">
                    {{user.firstName}}
                </latch-labeled-list-item>
                <latch-labeled-list-item class="half" label="Last Name">
                    {{user.lastName}}
                </latch-labeled-list-item>
                <div class="latch-divider"></div>
                <latch-labeled-list-item label="Email">
                    {{user.email}}
                </latch-labeled-list-item>
                <ng-container *ngIf="user.currentLoginDate || otherActiveUserSessions">
                    <div class="latch-divider"></div>
                    <latch-labeled-list-item *ngIf="user.currentLoginDate" class="half" label="Recent Logins">
                        <a class="inherit-color" routerLink="/console/recent-logins"
                            queryParamsHandling="preserve">
                            {{user.currentLoginDate | date: "MMMM d y 'at' h:mm a"}}
                        </a>
                    </latch-labeled-list-item>
                    <latch-labeled-list-item *ngIf="otherActiveUserSessions" class="half" label="Other Active Sessions">
                        <ng-container [ngSwitch]="otherActiveUserSessions.length === 0">
                            <span *ngSwitchCase="true">
                                None
                            </span>
                            <a class="inherit-color" *ngSwitchCase="false" routerLink="/console/active-sessions"
                                queryParamsHandling="preserve">
                                {{otherActiveUserSessions | activeSessions}}
                            </a>
                        </ng-container>
                    </latch-labeled-list-item>
                </ng-container>
                <ng-container *ngIf="user.email">
                    <div class="latch-divider"></div>
                    <latch-labeled-list-item label="Password">
                        <ng-container *ngIf="passwordStep === 'initiateChangePassword'">
                            <div class="buttons-container">
                                <button class="latch-button-link" (click)="setPasswordStep('confirmChangePassword')">Change Password</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="passwordStep === 'confirmChangePassword'">
                            <div>Are you sure you want to reset your password? We will send an email to {{user.email}}</div>
                            <div class="buttons-container">
                                <button class="latch-button-outline latch-dark" (click)="handleChangePassword(user.email)">Confirm</button>
                                <button class="latch-button-link" (click)="setPasswordStep('initiateChangePassword')">Cancel</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="passwordStep === 'confirmEmailSent'">
                            <div>
                                An email with your password reset link has been sent. If you don't see an email, please check your spam folder.
                            </div>
                            <div class="buttons-container">
                                <button class="latch-button-outline latch-dark" (click)="setPasswordStep('initiateChangePassword')">Confirm</button>
                            </div>
                        </ng-container>
                    </latch-labeled-list-item>
                </ng-container>
            </latch-list>
        </div>
    </div>
</latch-loading>