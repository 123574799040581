import { AccountPermissions } from './account';

export enum BuildingFeature {
  DoorcodeSuppression = 'DOORCODE_SUPPRESSION',
  Intercom = 'INTERCOM',
  SmartHome = 'SMART_HOME',
  RentPayments = 'RENT_PAYMENTS',
  Commercial = 'COMMERCIAL',
  SmartHomeNest = 'SMART_HOME_NEST',
  DeliveryAssistant = 'DELIVERY_ASSISTANT'
}

export interface Address {
  line1: string;
  line2?: string;
  line3?: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
}

export interface Technician {
  name: string;
  email?: string;
  phone?: string;
}

export enum PmsSource {
  REALPAGE = 'REALPAGE',
  ENTRATA = 'ENTRATA',
  YARDI = 'YARDI',
  YARDI_API = 'YARDI_API',
}

export interface Building {
  uuid: string;
  name: string;
  features?: BuildingFeature[];
  address?: Address;
  technician?: Technician;
  permissions?: AccountPermissions[];
  timezone?: string;
  pmsSource?: PmsSource;
  pmsUpdateTime?: Date;
}

export const fromJSON = (json: any) => Object.assign({
  timezone: 'America/New_York'
}, json);
