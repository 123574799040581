import * as _ from 'lodash';

/**
 * Coerce some value to an array (or an empty one for if it's null/undefined).
 */
export const arrayOf = <T>(value: T | T[]): T[] => {
  if (_.isNil(value)) {
    return [];
  }
  if (_.isArray(value)) {
    return value;
  }
  return [value];
};
